import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import es from "vuetify/es5/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  lang: {
    locales: { es },
    current: "es",
  },
  theme: {
    themes: {
      light: {
        white: "#fff",
        primary: "#009688",
        teal: "#009688",
        lightTeal: "#7DDFC3",
        darkGreen: "#226357",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#f44336",
        orange: "#f2804c",
        lightBlue: "#66b5f8",
        lightestBlue: "#deebfc",
        darkIndigo: "#0052A4",
        indigo: "#5383D3",
        pink: "#FF647C",
        darkPurple: "#9C27B1",
        purple: "#BE52F2",
        lightPurple: "#DBA5F5",
        lightestPurple: "#e8d3f3",
        blue: "#0084F4",
        yellow: "#ffc107",
        red: "#F44336",
        lightestGray: "#e7e7e7",
        lightGray: "#D4D4D4",
        midGray: "#666",
        midGray2: "#9e9e9e",
        darkGray: "#212529",
      },
    },
  },
});
