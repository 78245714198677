import {getField, updateField} from "vuex-map-fields";
import moment from "moment/moment";
import {partidaFacturaCalculations} from "../../js/PartidaFacturaCalculations.js";
import helper from "../../js/Helper.js";
import {clone} from "lodash";
import apiMunzen from "../../api/ApiMunzen.js";

let initialState = () => {
  return {
    headerLoaded: false,
    invoiceDataLoaded: false,
    partidasLoaded: false,
    editFacturaGlobalDataLoaded: true,
    structurePartida: {
      folioVenta: null,
      grupoImpuestosId: null,
      subtotal: null,
      impuestos: null,
      totalVenta: null,
      impuestosTrasladados: [],
    },
    formaPago: null,
    creationDate: null,
    month: null,
    year: null,
    partidas: [],
    serie: null,
  };
};


export default {
  namespaced: true,
  state: initialState,
  getters: {
    getField,
    componentsLoaded(state) {
      return (
        state.headerLoaded &&
        state.partidasLoaded &&
        state.invoiceDataLoaded &&
        state.editFacturaGlobalDataLoaded
      );
    },
    getInvoiceSubtotal(state) {
      let subtotal = 0;
      state.partidas.forEach((partida) => {
        subtotal += helper.currencyToNumber(partida.subtotal);
      });

      return subtotal;
    },
    getInvoiceImpuestosTrasladados(state) {
      let impuestosTrasladadosTotal = [];

      state.partidas.forEach((partida) => {
        let tmp = partidaFacturaCalculations.formatTaxesByType(
          partida.impuestosTrasladados,
          helper.currencyToNumber(partida.subtotal),
        );
        impuestosTrasladadosTotal.push(...tmp);
      });

      //Cada partida puede tener por ejemplo un impuesto de 16%, este código junta todos los impuestos con el mismo nombre
      //y suma sus montos
      return partidaFacturaCalculations.orderTaxes(
        partidaFacturaCalculations.groupTaxes(impuestosTrasladadosTotal),
      );
    },
    getInvoiceTotal(state) {
      let total = 0;
      state.partidas.forEach((partida) => {
        total += helper.currencyToNumber(partida.totalVenta);
      });

      return total;
    },
    getAllFormFields(state) {
      return state;
    },
  },
  mutations: {
    updateField,
    setCreationDateToCurrentDate(state) {
      state.creationDate = moment().format("YYYY-MM-DD");
    },
    headerLoaded(state) {
      state.headerLoaded = true;
    },
    invoiceDataLoaded(state) {
      state.invoiceDataLoaded = true;
    },
    partidasLoaded(state) {
      state.partidasLoaded = true;
    },
    resetPartidas(state) {
      state.partidas = [];
      state.partidas.push(clone(state.structurePartida));
    },
    pushEmptyPartida(state) {
      state.partidas.push(clone(state.structurePartida));
    },
    removePartida(state, partidaIndex) {
      state.partidas.splice(partidaIndex, 1);
    },
    incrementSerieFolio(state, folioCreated) {
      if (state.serie) {
        state.serie.folio = folioCreated + 1;
      }
    },
    resetState(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions:{
    timbrarFacturaGlobal({ state }, facturaGlobal,) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .post("/facturacion/V40/facturas-globales", facturaGlobal)
          .then((resp) => {
            const facturaGlobal = resp.data.data;
            resolve(facturaGlobal);
          })
          .catch((error) => {
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    saveFacturaGlobal({ state, commit, rootGetters, rootMutations }, facturaGlobal,) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .post("/facturacion/V40/facturas-globales/save", facturaGlobal)
          .then((resp) => {
            const facturaGlobal = resp.data.data;
            resolve(facturaGlobal);
          })
          .catch((error) => {
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
  }
}