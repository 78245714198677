import iziToast from "izitoast";

let callIziToast = function (message, title, id, time, icon, backgroundColor) {
  iziToast.destroy();
  iziToast.show({
    id: id,
    timeout: time,
    titleColor: "#fff",
    title: title,
    titleLineHeight: "20px",
    titleSize: "1.3em",
    layout: 2,
    message: message,
    messageLineHeight: "20px",
    messageSize: "1.1em",
    messageColor: "#fff",
    position: "topRight",
    backgroundColor: backgroundColor,
    progressBar: false,
    icon: icon,
    iconColor: "#fff",
    transitionIn: "bounceInLeft",
    close: false,
  });
};

export const iziToastFunctions = {
  /**
   * En cada función se puede enviar opcionalmente un id, para el caso en el que se desee mostrar diferentes
   * alertas con diferentes mensajes cada uno. Para mayor información consulta la documentación oficial http://izitoast.marcelodolce.com/
   */
  error(message, title = "", id = "default-id", time = 6000) {
    if (typeof message === "object") {
      //Esta validación es hecha especialmente cuando desde el back se regresa un error de validación de parámetros
      //Los errores se regresan en un objeto, como llave tiene el nombre del campo y como valores contiene un arreglo con todos los
      //errores que tiene ese campo
      const values = Object.values(message);
      message = values[0][0];
    }

    callIziToast(
      message,
      title,
      id,
      time,
      "fas fa-exclamation-circle",
      "#dc3545"
    );
  },

  success(message, title = "", id = "default-id", time = 6000) {
    callIziToast(message, title, id, time, "fas fa-check-circle", "#28a745");
  },

  warning(message, title = "", id = "default-id", time = 6000) {
    callIziToast(
      message,
      title,
      id,
      time,
      "fas fa-exclamation-triangle",
      "#cdac07"
    );
  },

  info(message, title = "", id = "default-id", time = 6000) {
    callIziToast(message, title, id, time, "fas fa-info-circle", "#17a2b8");
  },
  persistentErrorMessage: (message) => {
    iziToast.destroy();
    iziToast.show({
      message: `
<div class="d-flex">
    <i class="fa-regular fa-circle-exclamation fa-bounce" style="--fa-animation-iteration-count:5; --fa-animation-delay:5; display: flex; font-size: 2.4em; align-items: center; color: #f17883; padding-right: 14px"></i>
    <div class='regular-font'>${message}</div>
</div>`,
      messageLineHeight: "21",
      position: "bottomCenter",
      theme: "dark",
      backgroundColor: "#565c70",
      messageColor: "#ecf0f1",
      messageSize: "15",
      timeout: 25000,
      drag: false,
      toastOnce: true,
      layout: 2,
      maxWidth: "900px",
      progressBarColor: "#f17883",
    });
  },
  confirmDelete: function (message, aceptRedirect, id, fontAwsome4) {
    id = typeof id !== "undefined" ? id : "defult-id";
    iziToast.show({
      timeout: false,
      close: false,
      overlay: true,
      toastOnce: true,
      drag: false,
      id: id,
      zindex: 999,
      title: message,
      position: "center",
      icon:
        fontAwsome4 === true
          ? "fa fa-exclamation-circle"
          : "fas fa-exclamation-circle",
      iconColor: "#721c24",
      backgroundColor: "#FFF",
      buttons: [
        [
          '<button style="color: #007bff; background-color: transparent; background-image: none; border: 1px solid #007bff;">Aceptar</button>',
          function () {
            location.href = aceptRedirect;
          },
          false,
        ],
        [
          '<button style="    color: #6c757d; background-color: transparent; background-image: none; border: 1px solid #6c757d;">Cancelar</button>',
          function (instance, toast) {
            instance.hide({ transitionOut: "fadeOut" }, toast, "button");
          },
          false,
        ],
      ],
    });
  },

  mensajeFijoSuccess: function (mensaje, id, fontAwsome4) {
    id = typeof id !== "undefined" ? id : "defult-id";
    iziToast.destroy();
    iziToast.show({
      timeout: false,
      close: true,
      overlay: true,
      messageColor: "#155724",
      backgroundColor: "#a7d39d",
      iconColor: "#155724",
      toastOnce: true,
      drag: false,
      id: id,
      zindex: 999,
      message: mensaje,
      position: "center",
      icon: fontAwsome4 === true ? "fa fa-check-circle" : "fas fa-check-circle",
      buttons: [
        [
          "<button><b>Cerrar</b></button>",
          function (instance, toast) {
            instance.hide({ transitionOut: "fadeOut" }, toast, "button");
          },
          false,
        ],
      ],
    });
  },

  mensajeFijoError: function (mensaje, id, fontAwsome4) {
    id = typeof id !== "undefined" ? id : "defult-id";
    iziToast.show({
      timeout: false,
      close: true,
      overlay: true,
      messageColor: "#721c24",
      backgroundColor: "#FAD3D8",
      iconColor: "#721c24",
      drag: false,
      toastOnce: true,
      id: id,
      zindex: 999,
      message: mensaje,
      position: "center",
      icon:
        fontAwsome4 === true
          ? "fa fa-exclamation-circle"
          : "fas fa-exclamation-circle",
      buttons: [
        [
          "<button><b>Cerrar</b></button>",
          function (instance, toast) {
            instance.hide({ transitionOut: "fadeOut" }, toast, "button");
          },
          false,
        ],
      ],
    });
  },

  loader: function (message) {
    iziToast.show({
      class: "loader",
      title: message,
      timeout: 50000,
      messageColor: "#000000",
      position: "center",
      close: false,
      zindex: 9999,
      overlay: true,
      backgroundColor: "#fffeea",
      progressBar: false,
      image: webFolder + "/images/gcp/general/loader.svg",
      imageWidth: 70,
    });
  },

  hideLoader: function () {
    $("div").removeClass("iziToast-overlay fadeIn");
    var toastToHide = document.querySelector(".loader"); // Selector of your toast
    iziToast.hide(
      {
        transitionOut: "fadeOutUp",
      },
      toastToHide
    );
  },

  destroy: function () {
    iziToast.destroy();
  },
};
