import apiMunzen from "../../api/ApiMunzen";
import router from "../../router";
import helper from "../../js/Helper";

let initialState = () => {
  return {
    orders: null,
    searchParams: {
      data_search: "",
      current_page: 1,
      pages: [],
      items_perpage: helper.pagination.itemsPerPage,
      existingSearchResults: true,
    },
    generalLoading: true,
    pagesLoading: false,
    searching: false,
    currentOrder: null, // used to store the current order being viewed
  };
};
export default {
  namespaced: true,
  state: initialState,
  getters: {
    getOrders(state) {
      return state.orders;
    },
    getSearchParams(state) {
      return state.searchParams;
    },
    anySearchParamsSet(state) {
      return (
        state.searchParams.current_page > 1 ||
        state.searchParams.data_search !== ""
      );
    },
    getExistingSearchResults(state) {
      return state.searchParams.existingSearchResults;
    },
    getCurrentOrder(state) {
      return state.currentOrder;
    },
  },
  mutations: {
    setOrders(state, payload) {
      payload.orders.data.forEach((order) => {
        setOrderViewStatus(order);
      });
      state.orders = payload.orders;
    },
    setCurrentOrder(state, payload) {
      state.currentOrder = payload;
    },
    setExistingSearchResults(state, payload) {
      state.searchParams.existingSearchResults = payload.existingSearchResults;
    },
    setOrderViewStatusToInvoiced(state) {
      state.currentOrder.status = "invoiced";
      state.currentOrder.view_status = "Facturado";
      setOrderViewStatus(state.currentOrder);
    },
    setInvoiceData(state, invoiceData) {
      state.currentOrder.invoice_data = invoiceData;
    },
    resetState(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    fetchOrders(context, page = null) {
      context.state.searching = true;
      context.state.pagesLoading = true;

      if (page) {
        context.state.searchParams.current_page = page;
      } else {
        context.state.searchParams.current_page = parseInt(
          router.currentRoute.query.page || 1
        );
      }

      return new Promise((resolve, reject) => {
        apiMunzen
          .get(
            `/user-credits-payments?page=${context.state.searchParams.current_page}`
          )
          .then((resp) => {
            const orders = resp.data;
            context.commit("setOrders", { orders });
            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          })
          .finally(() => {
            context.state.generalLoading = false;
            context.state.searching = false;
            context.state.pagesLoading = false;
          });
      });
    },
    fetchOrder({ state, commit }, payload) {
      if (state.orders) {
        const order = state.orders.data.find(
          (order) => order.id === payload.id
        );
        if (order) {
          return new Promise((resolve) => {
            commit("setCurrentOrder", order);
            resolve(Object.assign({}, order));
          });
        }
      }

      return new Promise((resolve, reject) => {
        apiMunzen
          .get(`/user-credits-payments/${payload.id}`)
          .then((resp) => {
            const order = resp.data.data;
            commit("setCurrentOrder", order);
            setOrderViewStatus(order);
            resolve(order);
          })
          .catch((error) => {
            reject({
              success: false,
              error: error.response,
            });
          });
      });
    },
    invoiceOrder({ rootState }, payload) {
      const data = {
        client_enterprise_id: rootState.Auth.enterprise.id,
        user_credit_payment_id: payload.orderId,
      };

      return new Promise((resolve, reject) => {
        apiMunzen
          .post(
            "facturacion/V40/facturas-ingreso/subscription-plan-invoice",
            data
          )
          .then((resp) => {
            resolve(resp.data.data);
          })
          .catch((error) => {
            reject({
              success: false,
              error: error.response,
            });
          });
      });
    },
  },
};

function setOrderViewStatus(order) {
  if (order.status === "invoiced") {
    order.icon = "fas fa-stamp";
    order.color = "teal";
    order.textColor = "white";
  } else if (order.status === "paid") {
    order.icon = "fa-solid fa-receipt";
    order.color = "blue";
    order.textColor = "white";
  } else if (order.status === "free") {
    order.icon = "fa-solid fa-gift";
    order.color = "lightBlue";
    order.textColor = "white";
  }
}
