import apiMunzen from "../../api/ApiMunzen";

let initialState = () => {
  return {
    impuestosTrasladados: {
      iva: {
        name: "IVA",
        taxes: [],
      },
      iepsCuota: {
        name: "IEPS (Cuota)",
        taxes: [],
      },
      iepsTasa: {
        name: "IEPS (Tasa)",
        taxes: [],
      },
    },
    taxesType: {
      ivaTaxesArray: null,
      iepsCuotaTaxesArray: null,
      iepsTasaTaxesArray: null,
    },
  };
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getImpuestosTrasladados(state) {
      return state.impuestosTrasladados;
    },
    findIvaTaxByValue: (state) => (value) => {
      return state.impuestosTrasladados.iva.taxes.find(
        (tax) => tax.value === value,
      );
    },
    findTaxId: (state) => (taxToFind) => {
      //se busca él, id del impuesto trasladado con base en el tipo de impuesto (IVA, ISR o IEPS) y el valor (0.16, 0.08, etc.)
      return state.impuestosTrasladados[taxToFind.taxType].taxes.find(
        (tax) => tax.value === taxToFind.value,
      ).id;
    },
  },
  mutations: {
    setImpuestosTrasladados(state, payload) {
      state.impuestosTrasladados.iva.taxes = payload.impuestosTrasladados.iva;

      state.impuestosTrasladados.iepsCuota.taxes =
        payload.impuestosTrasladados.ieps_cuota;

      state.impuestosTrasladados.iepsTasa.taxes =
        payload.impuestosTrasladados.ieps_tasa;
    },
    resetTaxesType(state) {
      //this function is called when a taxe has benn enabled o disabled to force to refresh the
      //taxes form the API
      state.taxesType = {
        ivaTaxesArray: null,
        iepsCuotaTaxesArray: null,
        iepsTasaTaxesArray: null,
      };
    },
    resetState(state) {
      // acquire initial state
      state.taxesType = {
        ivaTaxesArray: null,
        iepsCuotaTaxesArray: null,
        iepsTasaTaxesArray: null,
      };

      state.allImpuestosTrasladados = [];
    },
  },
  actions: {
    getAllImpuestosTrasladados(
      { commit, state, rootState },
      isInitialConfiguration = false,
    ) {
      //Si ya tenemos los impuestos trasladados en memoria del navegador no se vuelve a hacer la petición
      if (state.impuestosTrasladados.iva.taxes.length > 0) return;

      const url = isInitialConfiguration
        ? `new-client/impuestos-trasladados/${rootState.InitialConfig.newClientToken}`
        : "/impuestos-trasladados";
      return new Promise((resolve, reject) => {
        apiMunzen
          .get(url)
          .then((resp) => {
            const impuestosTrasladados = resp.data.data;
            commit("setImpuestosTrasladados", { impuestosTrasladados });
            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    toggleStatus({ commit }, impuestoTrasladdadoId) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .get(`/impuestos-trasladados/toggle-status/${impuestoTrasladdadoId}`)
          .then(() => {
            commit("resetTaxesType");
            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
  },
};
