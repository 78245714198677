import helper from "../js/Helper";
import { iziToastFunctions } from "./IziToastFunctions";
import { taxesHelper as TaxesHelper } from "./TaxesHelper";

export const partidaFacturaCalculations = {
  /**
   * Calcula el precio total del producto con impuestos
   *
   * @param amount
   * @param discountAmount
   * @param impuestosTrasladados
   * @param impuestosRetenidos
   */
  productServiceTotal(
    amount,
    discountAmount,
    impuestosTrasladados = [],
    impuestosRetenidos = []
  ) {
    const baseAmount = amount - discountAmount;

    //Cálculo de los impuestos trasladados
    let totalImpuestosTrasladados = 0;
    if (impuestosTrasladados.length > 0) {
      totalImpuestosTrasladados = this.calculateImpuestosTrasladadosAmount(impuestosTrasladados);
    }

    //Cálculo de los impuestos retenidos
    let totalImpuestosRetenidos = 0;
    if (impuestosRetenidos.length > 0) {
      totalImpuestosRetenidos = this.calculateImpuestosRetenidosAmount(impuestosRetenidos);
    }

    return baseAmount + totalImpuestosTrasladados - totalImpuestosRetenidos;
  },
  /**
   * Calcula el precio del SubTotal de la partida con impuestos
   *
   * @param totalAmount
   * @param impuestosTrasladados
   * @param impuestosRetenidos
   */
  productServiceSubTotal(
    totalAmount,
    impuestosTrasladados = [],
    impuestosRetenidos = []
  ) {
    //const baseAmount = amount - discountAmount;

    //Cálculo de los impuestos trasladados
    let totalImpuestosTrasladados = 0;
    if (impuestosTrasladados.length > 0) {
      totalImpuestosTrasladados = this.calculateImpuestosTrasladadosAmount(impuestosTrasladados);
    }

    //Cálculo de los impuestos retenidos
    let totalImpuestosRetenidos = 0;
    if (impuestosRetenidos.length > 0) {
      totalImpuestosRetenidos = this.calculateImpuestosRetenidosAmount(impuestosRetenidos);
    }

    return totalAmount - totalImpuestosTrasladados + totalImpuestosRetenidos;
  },
  calculateImpuestosTrasladadosAmount(impuestosTrasladados) {
    let totalImpuestosTrasladados = 0;
    impuestosTrasladados.forEach((impuestoTrasladado) => {
      //impuestoTrasladado.value es un valor como .16
      totalImpuestosTrasladados += impuestoTrasladado.value;
    });

    return totalImpuestosTrasladados;
  },
  calculateImpuestosRetenidosAmount(impuestosRetenidos) {
    let totalImpuestosRetenidos = 0;
    impuestosRetenidos.forEach((impuestoRetenido) => {
      //impuestoRetenido.value es un valor como .16
      totalImpuestosRetenidos += impuestoRetenido.value;
    });

    return totalImpuestosRetenidos;
  },
  calculateDiscountPercentage(discountPercentage, amount) {
    //Se valida que el descuento sea un número válido
    if (!isNaN(discountPercentage) && !isNaN(parseFloat(discountPercentage))) {
      if (discountPercentage > 0 && discountPercentage < 1.0) {
        iziToastFunctions.error("El descuento no puede ser menor a 1");
        return;
      } else if (discountPercentage > 100.0) {
        iziToastFunctions.error("El descuento no puede ser mayor a 100");
        return;
      }
    } else {
      discountPercentage = 0;
    }

    //Se formatea a dos decimales el porcentaje y amount, que es lo permitido por las reglas de negocio
    discountPercentage = helper.roundPrice(discountPercentage);
    amount = helper.roundPrice(amount);

    return helper.roundPrice(amount * (discountPercentage / 100));
  },
  //Cálculo del monto dado por el precio unitario del producto X la cantidad
  calculateAmount(quantity, unityPrice) {
    return quantity * helper.currencyToNumber(unityPrice);
  },
  //Formatea los impuestos trasladados o retenidos para mostrarlos al usuario de una manera más entendible
  formatTaxesByType(
    taxes,
    baseAmount,
    decimalPlaces = 2,
    areImpuestosTrasladados = false
  ) {
    //Si son impuestos trasladados se ordena el arreglo para que el IEPS siempre aparezca primero
    //Esto es porque para obtener el importe del IVA primero se debe de obtener el importe del IEPS y sumarlo al precio base con el que se calcula el IVA
    //Si en el arreglo de impuestos trasladados no se encuentra el IEPS, entonces el cálculo ocurre de forma normal
    if (areImpuestosTrasladados) {
      taxes.sort((a, b) =>
        a.tax_type === "IEPS" ? -1 : b.tax_type === "IEPS" ? 1 : 0
      );
    }

    let iepsAmount = 0.0;

    let formatTaxes = [];
    taxes.forEach((tax) => {
      if (tax.tax_type === "IEPS") {
        iepsAmount += helper.roundPrice(baseAmount * tax.value, decimalPlaces);
      } else {
        baseAmount += iepsAmount;
      }

      const taxDescription = TaxesHelper.formatTaxName(tax);

      //Se añade el impuesto trasladado o retenido al arreglo formatTaxes
      formatTaxes.push({
        name: taxDescription,
        value: helper.roundPrice(baseAmount * tax.value, decimalPlaces),
      });
    });
    return formatTaxes;
  },
  //Formatea los impuestos trasladados para mostrarlos al usuario de una manera más entendible
  //El cálculo es para obtener el subTotal basándonos en el total y los impuestos
  formatTaxesByTypeWithTotalAmount(
    taxes,
    totalAmount,
    decimalPlaces = 2
  ) {
    //Si son impuestos trasladados se ordena el arreglo para que el IEPS siempre aparezca primero
    //Esto es porque para obtener el importe del IVA primero se debe de obtener el importe del IEPS y sumarlo al precio base con el que se calcula el IVA
    //Si en el arreglo de impuestos trasladados no se encuentra el IEPS, entonces el cálculo ocurre de forma normal
      taxes.sort((a, b) =>
        a.tax_type === "IEPS" ? -1 : b.tax_type === "IEPS" ? 1 : 0
      );


    let iepsAmount = 0.0;

    let formatTaxes = [];
    taxes.forEach((tax) => {
      if (tax.tax_type === "IEPS") {
        iepsAmount += helper.roundPrice(totalAmount - (totalAmount / (1 + tax.value)), decimalPlaces);
      } else {
        totalAmount = totalAmount - iepsAmount;
      }

      const taxDescription = TaxesHelper.formatTaxName(tax);

      //Se añade el impuesto trasladado o retenido al arreglo formatTaxes
      formatTaxes.push({
        name: taxDescription,
        value: helper.roundPrice(totalAmount - (totalAmount / (1 + tax.value)), decimalPlaces),
      });
    });
    return formatTaxes;
  },
  //Agrupa todos los impuestos trasladados o retenidos por tipo y suma sus valores
  //Es importante usar antes la función formatTaxesByType que prepara el arreglo necesario para ejecutar esta función
  groupTaxes(taxes) {
    let groupedTaxes = [];
    taxes.forEach((tax) => {
      const indexGroupedTax = groupedTaxes.findIndex(
        (impuestoAgrupado) => impuestoAgrupado.name === tax.name
      );

      //Si no se encuentra el índice del impuesto ya agrupado es por que es uno nuevo y se añade al array groupedTaxes
      if (indexGroupedTax === -1) {
        groupedTaxes.push({
          name: tax.name,
          value: tax.value,
        });
      } else {
        //Ya existe el impuesto en groupedTaxes y solo se suma el valor
        groupedTaxes[indexGroupedTax].value += tax.value;
      }
    });

    return groupedTaxes;
  },
  orderTaxes(taxes) {
    if (taxes.length === 0) return [];

    //Se ordenan los impuestos de la siguiente manera:
    //1. IEPS
    //2. IVA
    //3. ISR
    //Cada subgrupo se ordena de mayor a menor tasa
    const isIEPS = ({ name }) => name.toLowerCase().includes("ieps");
    const isIVA = ({ name }) => name.toLowerCase().includes("iva");
    const isISR = ({ name }) => name.toLowerCase().includes("isr");
    const getTasa = ({ name }) => parseInt(name.match(/\d+/));

    taxes.sort((a, b) =>
      isIEPS(a) && !isIEPS(b)
        ? -1
        : !isIEPS(a) && isIEPS(b)
        ? 1
        : isIVA(a) && !isIVA(b)
        ? -1
        : !isIVA(a) && isIVA(b)
        ? 1
        : isISR(a) && !isISR(b)
        ? 1
        : !isISR(a) && isISR(b)
        ? -1
        : getTasa(b) - getTasa(a) || a.value - b.value
    );

    return taxes;
  },
};
