import apiMunzen from "../../api/ApiMunzen";
import { getField, updateField } from "vuex-map-fields";
import { partidaFacturaCalculations } from "../../js/PartidaFacturaCalculations";
import moment from "moment";
import helper from "../../js/Helper.js";
import {iziToastFunctions} from "../../js/IziToastFunctions.js";

let initialState = () => {
  return {
    headerLoaded: false,
    invoiceDataLoaded: false,
    partidasLoaded: false,
    editPreFacturaDataLoaded: true,
    structurePartida: {
      productService: null,
      cantidad: null,
      unidadMedida: null,
      precioUnitario: null,
      descuento: null,
      descuentoMonto: null,
      subtotal: null,
      importeBase: null,
      total: null,
      impuestosRetenidos: [],
      impuestosTrasladados: [],
      searchingProductService: false,
      hideNoDataFound: true,
      cuentaPredialNumber: null,
    },
    listOfClientsSearched: [],
    client: {
      id: null,
      usoCfdi: null,
      email: null,
      address: null,
      metodoPago: null,
      formaPago: null,
      regimenFiscal: null,
      rfc: null,
      description: null,
    },
    partidas: [],
    serie: null,
    creationDate: null,
  };
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getField,
    getInvoiceSubtotal(state) {
      let subtotal = 0;
      state.partidas.forEach((partida) => {
        subtotal += partida.subtotal;
      });

      return subtotal;
    },
    getInvoiceDiscountAmount(state) {
      let discountAmount = 0;
      state.partidas.forEach((partida) => {
        discountAmount += partida.descuentoMonto;
      });

      return discountAmount;
    },
    getInvoiceImpuestosTrasladados(state) {
      let impuestosTrasladadosTotal = [];

      state.partidas.forEach((partida) => {
        let tmp = partidaFacturaCalculations.formatTaxesByType(
          partida.impuestosTrasladados,
          partida.importeBase,
        );
        impuestosTrasladadosTotal.push(...tmp);
      });

      //Cada partida puede tener por ejemplo un impuesto de 16%, este código junta todos los impuestos con el mismo nombre
      //y suma sus montos
      return partidaFacturaCalculations.orderTaxes(
        partidaFacturaCalculations.groupTaxes(impuestosTrasladadosTotal),
      );
    },
    getInvoiceImpuestosRetenidos(state) {
      let impuestosRetenidosTotal = [];

      state.partidas.forEach((partida) => {
        let tmp = partidaFacturaCalculations.formatTaxesByType(
          partida.impuestosRetenidos,
          partida.importeBase,
        );
        impuestosRetenidosTotal.push(...tmp);
      });

      //Cada partida puede tener por ejemplo un impuesto de 16%, este código junta todos los impuestos con el mismo nombre
      //y suma sus montos
      return partidaFacturaCalculations.orderTaxes(
        partidaFacturaCalculations.groupTaxes(impuestosRetenidosTotal),
      );

    },
    getInvoiceTotal(state) {
      let total = 0;
      state.partidas.forEach((partida) => {
        total += partida.total;
      });

      return total;
    },
    getAllFormFields(state) {
      return state;
    },
    getPartidas(state) {
      return state.partidas;
    },
    componentsLoaded(state) {
      return (
        state.headerLoaded &&
        state.partidasLoaded &&
        state.invoiceDataLoaded &&
        state.editPreFacturaDataLoaded
      );
    },
  },
  mutations: {
    updateField,
    resetComponentsLoaded(state) {
      state.headerLoaded = false;
      state.partidasLoaded = false;
      state.invoiceDataLoaded = false;
      state.editPreFacturaDataLoaded = true;
    },
    removePartida(state, partidaIndex) {
      state.partidas.splice(partidaIndex, 1);
    },
    cleanClient(state) {
      const is = initialState();
      state.client = is.client;
    },
    setCreationDateToCurrentDate(state) {
      state.creationDate = moment().format("YYYY-MM-DD");
    },
    headerLoaded(state) {
      state.headerLoaded = true;
    },
    invoiceDataLoaded(state) {
      state.invoiceDataLoaded = true;
    },
    partidasLoaded(state) {
      state.partidasLoaded = true;
    },
    resetPartidas(state) {
      state.partidas = [];
    },
    pushPartida(state, partida) {
      state.partidas.push(partida);
    },
    incrementSerieFolio(state, folioCreated) {
      if (state.serie) {
        state.serie.folio = folioCreated + 1;
      }
    },
    resetState(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    timbrarFacturaIngreso({ state, commit, rootGetters, rootMutations }, facturaIngreso,) {

      const enterprise = rootGetters['Auth/getAuthEnterprise'];
      validateInvoice(enterprise,state);

      return new Promise((resolve, reject) => {
        apiMunzen
          .post("/facturacion/V40/facturas-ingreso", facturaIngreso)
          .then((resp) => {
            const facturaIngreso = resp.data.data;
            resolve(facturaIngreso);
          })
          .catch((error) => {
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    savePreFacturaIngreso({ state, commit, rootGetters, rootMutations }, facturaIngreso,) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .post("/facturacion/V40/pre-facturas-ingreso", facturaIngreso)
          .then((resp) => {
            const facturaIngreso = resp.data.data;
            resolve(facturaIngreso);
          })
          .catch((error) => {
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    timbrarPreFacturaIngreso({ state, rootGetters }, data) {

      const enterprise = rootGetters['Auth/getAuthEnterprise'];
      validateInvoice(enterprise,state);

      return new Promise((resolve, reject) => {
        apiMunzen
          .post(
            `/facturacion/V40/pre-facturas-ingreso/${data.preFacturaIngresoId}/timbrar`,
            data.facturaIngresoData,
          )
          .then((resp) => {
            const facturaIngreso = resp.data.data;
            resolve(facturaIngreso);
          })
          .catch((error) => {
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    timbrarWithPreFacturaOriginalData({ state, rootGetters }, data) {

      const enterprise = rootGetters['Auth/getAuthEnterprise'];
      validateInvoice(enterprise,state);

      return new Promise((resolve, reject) => {
        apiMunzen
          .post(
            `/facturacion/V40/pre-facturas-ingreso/${data.preFacturaIngresoId}/timbrar-with-pre-factura-original-data`,
            data.facturaIngresoData,
          )
          .then((resp) => {
            const facturaIngreso = resp.data.data;
            resolve(facturaIngreso);
          })
          .catch((error) => {
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    deletePreFacturaIngreso({ state }, idPreFactura) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .delete(`/facturacion/V40/pre-facturas-ingreso/${idPreFactura}`)
          .then(() => {
            resolve({
              success: true,
            });
          })
          .catch((error) => {
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    cancelFacturaIngreso({}, cancelData) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .post("/facturacion/V40/cancelar-factura", cancelData)
          .then((resp) => {
            const facturaIngresoCancelledInfo = resp.data.data;
            resolve(facturaIngresoCancelledInfo);
          })
          .catch((error) => {
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
  },
};

function validateInvoice(enterprise,state) {

  if (enterprise.regimen_fiscal === "626") {
    //if client is a persona moral then we have to check if all partidas have impuestos retenidos
    if (helper.getPersonalidad(state.client.rfc) === "Moral") {

      for (const partida of state.partidas) {
        if (partida.impuestosRetenidos.length === 0 || partida.impuestosTrasladados.length === 0) {
          iziToastFunctions.persistentErrorMessage("Para facturar a una persona moral, todos los conceptos deben incluir el impuesto trasladado y los impuestos retenidos correspondientes." +
            "<br><br>Comúnmente se aplica el IVA trasladado 16% junto con el ISR retenido del 1.25% y el IVA retenido de 2/3 (10.6667%), a menos que tu producto esté exento de IVA, en cuyo caso solo se retiene el ISR del 1.25%.");
          throw {
            success: false,
            statusCode: 400,
          };
        }
      }

    }
    else{
      //if client is a persona fisica then we have to check if all partidas have only impuestos trasladados
      for (const partida of state.partidas) {
        if (partida.impuestosRetenidos.length !== 0) {
          iziToastFunctions.persistentErrorMessage("Para facturar a una persona física, todos los conceptos deben tener solo impuestos trasladados." +
            "<br><br>Comúnmente solo se aplica el IVA trasladado 16%, IVA 0% o IVA Exento.");
          throw {
            success: false,
            statusCode: 400,
          };
        }
      }
    }
  }
}