import apiMunzen from "../../api/ApiMunzen";

let initialState = () => {
  return {
    showPasswordModal: false,
    showChangeRegisteredEmailModal: false,
    showUpdatePersonalInfo: false,
  };
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getShowPasswordModal(state) {
      return state.showPasswordModal;
    },
    getShowChangeRegisteredEmailModal(state) {
      return state.showChangeRegisteredEmailModal;
    },
    getShowUpdatePersonalInfoModal(state) {
      return state.showUpdatePersonalInfo;
    },
  },
  mutations: {
    openPasswordModal(state) {
      state.showPasswordModal = true;
    },
    closePasswordModal(state) {
      state.showPasswordModal = false;
    },
    openChangeRegisteredEmailModal(state) {
      state.showChangeRegisteredEmailModal = true;
    },
    closeChangeRegisteredEmailModal(state) {
      state.showChangeRegisteredEmailModal = false;
    },
    openUpdatePersonalInfoModal(state) {
      state.showUpdatePersonalInfo = true;
    },
    closeUpdatePersonalInfoModal(state) {
      state.showUpdatePersonalInfo = false;
    },
    resetState(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    updatePassword({ state }, passwordData) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .post("/users/change-password", passwordData)
          .then(() => {
            resolve(true);
          })
          .catch((error) => {
            reject({
              apiErrorClientMessage: error.apiErrorClientMessage,
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    changeRegisteredEmail({}, email) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .post("/users/change-personal-email", email)
          .then(() => resolve(true))
          .catch((error) => {
            reject({
              success: false,
              error: error.response,
            });
          });
      });
    },
    resendAccountVerificationToken({}, payload) {
      const data = {
        email: payload.newEmail,
      };
      return new Promise((resolve, reject) => {
        apiMunzen
          .post("/users/resend-token-personal-email", data)
          .then(() => resolve(true))
          .catch((error) => {
            reject({
              success: false,
              error: error.response,
            });
          });
      });
    },
    validateAccountVerificationToken({}, payload) {
      const data = {
        email_verification_token: payload.accountVerificationToken,
        email: payload.newEmail,
      };
      return new Promise((resolve, reject) => {
        apiMunzen
          .post("/users/validate-token-personal-email", data)
          .then(() => resolve(true))
          .catch((error) => {
            reject({
              success: false,
              error: error.response,
            });
          });
      });
    },
    updatePersonalInfo({}, payload) {
      const data = {
        name: payload.name,
        phone: payload.phone,
      };

      return new Promise((resolve, reject) => {
        apiMunzen
          .post("/users/change-personal-information", data)
          .then(() => resolve(true))
          .catch((error) => {
            reject({
              success: false,
              error: error.response,
            });
          });
      });
    },
    sendHelpRequest({}, payload) {
      const data = {
        question: payload.question,
        question_details: payload.questionDetails,
      };

      return new Promise((resolve, reject) => {
        apiMunzen
          .post("/help-center/question-form", data)
          .then(() => resolve(true))
          .catch((error) => {
            reject({
              success: false,
              error: error.response,
            });
          });
      });
    },
  },
};
