import apiMunzen from "../../api/ApiMunzen";
import { getField, updateField } from "vuex-map-fields";

let initialState = () => {
  return {
    allTaxesGroups: [],
    allImpuestosTrasladadosTaxesGroups: [],
    taxesGroupFormData: {
      name: "",
      objetoImpuesto: "",
      selectedImpuestosRetenidos: [],
      selectedImpuestosTrasladado: [],
    },
  };
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getField,
    getAllTaxesGroups(state) {
      return state.allTaxesGroups;
    },
    getAllImpuestosTrasladadosTaxesGroups(state) {
      return state.allImpuestosTrasladadosTaxesGroups;
    }
  },
  mutations: {
    updateField,
    setSelectedTaxesGroup(state, payload) {
      //limpiamos posibles impuestos que no deben estar seleccionados
      state.taxesGroupFormData.selectedImpuestosTrasladado = [];
      state.taxesGroupFormData.selectedImpuestosRetenidos = [];

      state.taxesGroupFormData.name = payload.taxesGroup.name;
      state.taxesGroupFormData.objetoImpuesto =
        payload.taxesGroup.objeto_impuesto_key;

      //Se seleccionan los impuestos trasladados que seleccionó el usuario para mostrarlos seleccionados en la vista
      for (const [, taxValues] of Object.entries(
        payload.taxesGroup.impuestos_trasladados,
      )) {
        taxValues.forEach((property) => {
          if (property.selected === true) {
            state.taxesGroupFormData.selectedImpuestosTrasladado.push(
              property.id,
            );
          }
        });
      }

      //Se seleccionan los impuestos retenidos que seleccionó el usuario
      //para mostrarlos seleccionados en la vista
      for (const [, taxValues] of Object.entries(
        payload.taxesGroup.impuestos_retenidos,
      )) {
        taxValues.forEach((property) => {
          if (property.selected === true) {
            state.taxesGroupFormData.selectedImpuestosRetenidos.push(
              property.id,
            );
          }
        });
      }
    },
    setAllTaxesGroups(state, payload) {
      state.allTaxesGroups = payload.taxesGroups;
    },
    setAllImpuestosTrasladadosTaxesGroups(state, payload) {
      state.allImpuestosTrasladadosTaxesGroups = payload.taxesGroups;
    },
    resetFormData(state) {
      const s = initialState().taxesGroupFormData;
      Object.keys(s).forEach((key) => {
        state.taxesGroupFormData[key] = s[key];
      });
    },
    resetState(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    fetchAllTaxesGroups({ commit, rootState }, isInitialConfiguration = false) {
      const url = isInitialConfiguration
        ? `new-client/rubros-impuesto/${rootState.InitialConfig.newClientToken}`
        : "/rubros-impuestos";

      return new Promise((resolve, reject) => {
        apiMunzen
          .get(url)
          .then((resp) => {
            const taxesGroups = resp.data.data;
            commit("setAllTaxesGroups", { taxesGroups });
            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    fetchTaxesGroupsWithImpuestosTrasladadosOnly({ commit }) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .get("/rubros-impuestos/get-impuestos-trasladados")
          .then((resp) => {
            const taxesGroups = resp.data.data;
            commit("setAllImpuestosTrasladadosTaxesGroups", { taxesGroups });
            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    fetchTaxesGroup({ commit }, taxesGroupId) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .get(`/rubros-impuestos/${taxesGroupId}`)
          .then((resp) => {
            const taxesGroup = resp.data.data;
            commit("setSelectedTaxesGroup", { taxesGroup });
            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    updateTaxesGroup({ state, rootState }, taxesGroup) {
      const url = taxesGroup.isInitialConfiguration
        ? `new-client/rubros-impuesto/${taxesGroup.id}/${rootState.InitialConfig.newClientToken}`
        : `/rubros-impuestos/${taxesGroup.id}`;

      const data = {
        enterprise_id: taxesGroup.isInitialConfiguration
          ? rootState.InitialConfig.newClientInfo.enterprise_id
          : rootState.Auth.enterprise.id,
        name: state.taxesGroupFormData.name,
        impuestos_trasladados_ids:
          state.taxesGroupFormData.selectedImpuestosTrasladado,
        impuestos_retenidos_ids:
          state.taxesGroupFormData.selectedImpuestosRetenidos,
        objeto_impuesto_key: state.taxesGroupFormData.objetoImpuesto,
      };

      return new Promise((resolve, reject) => {
        apiMunzen
          .put(url, data)
          .then((resp) => {
            const taxesGroupUpdated = resp.data.data;

            //Se busca el grupo y se actualizan sus valores reemplazándolo
            const taxesGroupIndex = state.allTaxesGroups.findIndex(
              (value) => value.id === taxesGroupUpdated.id,
            );
            state.allTaxesGroups[taxesGroupIndex] = taxesGroupUpdated;

            resolve(taxesGroupUpdated);
          })
          .catch((error) => {
            console.log(error.response);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    //Se crea un nuevo grupo pasando como parámetro una bandera para saber si es configuración inicial o no
    createTaxesGroup({ state, rootState, commit },
      isInitialConfiguration = false,
    ) {
      const data = {
        enterprise_id: isInitialConfiguration
          ? rootState.InitialConfig.newClientInfo.enterprise_id
          : rootState.Auth.enterprise.id,
        name: state.taxesGroupFormData.name,
        impuestos_trasladados_ids:
          state.taxesGroupFormData.selectedImpuestosTrasladado,
        impuestos_retenidos_ids:
          state.taxesGroupFormData.selectedImpuestosRetenidos,
        objeto_impuesto_key: state.taxesGroupFormData.objetoImpuesto,
      };

      const url = isInitialConfiguration
        ? `new-client/rubros-impuesto/${rootState.InitialConfig.newClientToken}`
        : "/rubros-impuestos";

      return new Promise((resolve, reject) => {
        apiMunzen
          .post(url, data)
          .then((resp) => {
            const taxesGroupsCreated = resp.data.data;

            //Se agrega el nuevo grupo a la lista del index
            state.allTaxesGroups.push(taxesGroupsCreated);

            //Se limpia el formulario de los impuestos que se quedaron seleccionados
            commit("resetFormData");
            resolve(taxesGroupsCreated);
          })
          .catch((error) => {
            console.log(error.response);
            //Se limpia el formulario de los impuestos que se quedaron seleccionados
            commit("resetFormData");
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    deleteTaxesGroup({ state, rootState }, taxesGroup) {
      const url = taxesGroup.isInitialConfiguration
        ? `new-client/rubros-impuesto/${taxesGroup.id}/${rootState.InitialConfig.newClientToken}`
        : `/rubros-impuestos/${taxesGroup.id}`;

      return new Promise((resolve, reject) => {
        apiMunzen
          .delete(url)
          .then(() => {
            const taxesGroupIndex = state.allTaxesGroups.findIndex(
              (value) => value.id === taxesGroup.id,
            );
            state.allTaxesGroups.splice(taxesGroupIndex, 1);
            resolve({
              success: true,
            });
          })
          .catch((error) => {
            reject({
              success: false,
              error,
            });
          });
      });
    },
  },
};
