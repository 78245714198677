import Vue from "vue"
import helper from '../js/Helper'

Vue.filter('toCurrency', function (value, precision = 2) {
  if (typeof value !== "number") {
    return value;
  }

  return helper.numberToCurrency(value, precision)

});
