import Promise from "lodash/_Promise";
import apiMunzen from "../../api/ApiMunzen";

let initialState = () => {
  return {
    currentPayment: {
      id: null,
      paymentMethod: null,
      paymentGatewayOrderId: null,
      paymentDate: null,
      subscriptionPlanName: null,
      subscriptionPlanAmount: null,
      subscriptionPlanGiftAmount: null,
      subscriptionPlanPrice: null,
      status: null,
    },
  };
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getCurrentPayment(state) {
      return state.currentPayment;
    },
  },
  mutations: {
    setCurrentPayment(state, payload) {
      state.currentPayment.id = payload.id;
      state.currentPayment.paymentMethod = payload.payment_method;
      state.currentPayment.paymentGatewayOrderId =
        payload.payment_gateway_order_id;
      state.currentPayment.paymentDate = payload.payment_date;
      state.currentPayment.subscriptionPlanName =
        payload.subscription_plan_name;
      state.currentPayment.subscriptionPlanAmount =
        payload.subscription_plan_amount;
      state.currentPayment.subscriptionPlanGiftAmount =
        payload.subscription_plan_gift_amount;
      state.currentPayment.subscriptionPlanPrice =
        payload.subscription_plan_price;
      state.currentPayment.status = payload.status;
    },
    resetState(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    //Cambiar a un js de payments
    fetchPaymentByGatewayOrderId({ commit }, paymentGatewayOrderId) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .get(
            "payments/credits/get-order-from-payment-gateway/" +
              paymentGatewayOrderId
          )
          .then((response) => {
            commit("setCurrentPayment", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            console.log(error.response);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
  },
};
