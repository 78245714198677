import apiMunzen from "../../api/ApiMunzen.js";
import {getField, updateField} from "vuex-map-fields";
import moment from "moment";

let initialState = () => {
  return {
    facturasGlobales: null, //Contiene la información de las facturas en la paginación, no contiene toda la info de la factura global
    facturasGlobalesYears: null, //Contiene los años en los que se han creado facturas globales por default se mostrará el año actual y el anterior
    consultedFacturasGlobales: [], //Aquí se guardarán las facturas globales consultadas para no llamarlas de nuevo a la API, considerar al hacer eliminaciones
    currentShowFactura: null,
    searchParams: {
      filters: {
        //Al actualizar estos campos actualizar la función resetFilters
        year: moment().year().toString(),
      },
    },
    generalLoading: true,
    pagesLoading: false,
    searching: false,
    fetchingFactura: false,
    cancelingFactura: false,
    showWelcomePage: null, //Esta propiedad nos permite mostrar la primera pantalla cuando no se tiene ni una sola factura creada
  };
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getField,
    getFacturasGlobalesList(state) {
      return state.facturasGlobales.data;
    },
    getGeneralLoading(state) {
      return state.generalLoading;
    },
    getSearchingLoading(state) {
      return state.searching;
    },
    getFacturasGlobalesYears(state) {
      return state.facturasGlobalesYears;
    },
    getCurrentShowFactura(state) {
      return state.currentShowFactura;
    },
    getFetchingFactura(state) {
      return state.fetchingFactura;
    },
    getCancelingFactura(state) {
      return state.cancelingFactura;
    },
  },
  mutations: {
    updateField,
    setFacturasGlobales(state, payload) {
      payload.facturasGlobales.data.forEach((facturaGlobal) => {
        setFacturaGlobalViewStatus(facturaGlobal);
      });

      state.facturasGlobales = payload.facturasGlobales;

    },
    setFacturasGlobalesYears(state, payload) {
      state.facturasGlobalesYears = payload.facturasGlobalesYears.data;
    },
    setFalseGeneralLoading(state) {
      state.generalLoading = false;
    },
    appendToConsultedFacturas(state, facturaGlobal) {
      //Es un arreglo con toda la información de las facturas consultadas, para no mandar hacer otro llamado al API
      state.consultedFacturasGlobales.push(facturaGlobal);
    },
    removeFromConsultedFacturas(state, id) {
      //Se elimina el registro de la tabla de la paginación
      let index = state.consultedFacturasGlobales.findIndex(
        (consultedFacturaGlobal) => consultedFacturaGlobal.id === id,
      );

      if (index !== -1) {
        state.consultedFacturasGlobales.splice(index, 1);
      }
    },
    resetConsultedFacturas(state) {
      state.consultedFacturasGlobales = [];
    },
    setCurrentFacturaGlobal(state, factura) {
      //Se establece la factura que va a ser consultada, comúnmente esta factura se obtiene desde el arreglo
      //de consultedFacturas
      //Esto se hace para que pueda ser consultada desde diferentes componentes
      state.currentShowFactura = factura;

      setFacturaGlobalViewStatus(state.currentShowFactura);

      state.fetchingFactura = false;
    },
    updateFacturaGlobal(state, factura) {
      //Se actualiza la factura que se encuentra en el arreglo de consultedFacturas
      //y la info de la factura que se está consultando en la vista detalle
      state.currentShowFactura = factura;
      setFacturaGlobalViewStatus(state.currentShowFactura);

      //Se actualiza la factura en el arreglo de consultedFacturas, si lo encuentra
      let index = state.consultedFacturasGlobales.findIndex(
        (consultedFacturaIngreso) => consultedFacturaIngreso.id === factura.id,
      );
      if (index !== -1) {
        state.consultedFacturasGlobales[index] = factura;
      }
    },
    resetState(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    async fetchFacturasGlobales({commit}) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .get(
            '/facturacion/V40/facturas-globales',
          )
          .then((resp) => {
            const facturasGlobales = resp.data;
            commit("setFacturasGlobales", {facturasGlobales});
            resolve(true);
          })
          .catch((error) => {
            console.log(error.response);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    fetchFacturasGlobalesYears({commit}) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .get(
            '/facturacion/V40/facturas-globales/get-years-for-facturas-globales',
          )
          .then((resp) => {
            const facturasGlobalesYears = resp.data;
            commit("setFacturasGlobalesYears", {facturasGlobalesYears});
            resolve(true);
          })
          .catch((error) => {
            console.log(error.response);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    searchFacturasGlobales({commit, state}) {
      state.searching = true;

      const searchParams = {
        year: state.searchParams.filters.year,
      };

      return new Promise((resolve, reject) => {
        apiMunzen
          .post(
            `/facturacion/V40/facturas-globales/search`,
            searchParams,
          )
          .then((resp) => {
            const facturasGlobales = resp.data;
            commit("setFacturasGlobales", {facturasGlobales});

            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            reject(false);
          })
          .finally(() => {
            state.searching = false;
          });
      });
    },
    fetchFacturaGlobal({state, commit}, facturaGlobalId) {
      state.fetchingFactura = true;

      //búsqueda para saber si la factura está en las facturas que ya se han consultado
      const facturaGlobal = state.consultedFacturasGlobales.find((consultedFacturaGlobal) =>
        parseInt(consultedFacturaGlobal.id) === parseInt(facturaGlobalId)
      );

      if (facturaGlobal) {
        commit("setCurrentFacturaGlobal", facturaGlobal);
        return facturaGlobal;
      }

      return new Promise((resolve, reject) => {
        apiMunzen
          .get(`/facturacion/V40/facturas-globales/${facturaGlobalId}`)
          .then((resp) => {
            const facturaGlobalFetched = resp.data.data;
            commit("appendToConsultedFacturas", facturaGlobalFetched);
            commit("setCurrentFacturaGlobal", facturaGlobalFetched);
            resolve(facturaGlobalFetched);
          })
          .catch((error) => {
            reject({
              success: false,
              error: error.response,
            });
          });
      });
    },
  },
}

function setFacturaGlobalViewStatus(facturaGlobal) {
  if (facturaGlobal.status === "timbrada") {
    facturaGlobal.icon = "fas fa-stamp";
    facturaGlobal.color = "teal";
    facturaGlobal.textColor = "white";
    facturaGlobal.actionClass = "details";
    facturaGlobal.actionIcon = "fas fa-file-invoice";
    facturaGlobal.to = {
      name: 'facturacion.facturas-globales.detail',
      params: {facturaGlobalId: facturaGlobal.id},
    }
  }

  else if (facturaGlobal.status === "saved") {

    facturaGlobal.icon = "fa-solid fa-receipt";
    facturaGlobal.color = "blue";
    facturaGlobal.textColor = "white";

    //Solo es posible editar la factura si no es mayor a 2 años
    if (moment().diff(moment(facturaGlobal.year, "YYYY"), "years") <= 2) {
      facturaGlobal.to = {
        name: 'facturacion.facturas-globales.edit',
        params: {facturaGlobalId: facturaGlobal.id},
      }
      facturaGlobal.actionClass = "edit";
      facturaGlobal.actionIcon = "fas fa-file-invoice";
    } else {
      facturaGlobal.to = {
        name: 'facturacion.facturas-globales.detail',
        params: {facturaGlobalId: facturaGlobal.id},
      }
      facturaGlobal.actionClass = "details";
      facturaGlobal.actionIcon = "fas fa-file-invoice";
    }

  }

  else if (facturaGlobal.status === "unissued") {
    facturaGlobal.icon = "fas fa-exclamation-circle";
    facturaGlobal.color = "midGray";
    facturaGlobal.textColor = "white";
    facturaGlobal.actionClass = "create";
    facturaGlobal.actionIcon = "fas fa-plus";
    facturaGlobal.to = {
      name: 'facturacion.facturas-globales.create',
      params: {month: facturaGlobal.month_key, year: facturaGlobal.year},
    }
  }

  else if (facturaGlobal.status === "cancelled") {
    facturaGlobal.icon = "fa-solid fa-file-circle-xmark";
    facturaGlobal.color = "red";
    facturaGlobal.textColor = "white";
    facturaGlobal.actionClass = "details";
    facturaGlobal.actionIcon = "fas fa-file-invoice";
    facturaGlobal.to = {
      name: 'facturacion.facturas-globales.detail',
      params: {facturaGlobalId: facturaGlobal.id},
    }
  }
}