import Vue from "vue";
import helper from "../js/Helper";


Vue.filter("humanReadableDate", function (date, showHour = false) {

  if (showHour) {
    return  helper.humanReadableDate(date, true);
  } else {
    return helper.humanReadableDate(date);
  }
});

Vue.filter("shortHumanReadableDate", function (date, showHour = false) {

  if (showHour) {
    return helper.shortHumanReadableDate(date, true);
  } else {
    return helper.shortHumanReadableDate(date);
  }
});
