import apiMunzen from "../../api/ApiMunzen";
import router from "../../router";
import FileSaver from "file-saver";
import helper from "../../js/Helper";

let initialState = () => {
    return {
        productsServices: null,
        searchParams: {
            data_search: "",
            current_page: 1,
            pages: [],
            items_perpage: helper.pagination.itemsPerPage,
            existingSearchResults: true,
            defaultPagesInPaginationBar: helper.pagination.pagesNavigationBarQuantity, //this is the number of showed pages the user can move in pagination bar
            recordsPerPage: helper.pagination.paginationSizeOptions,
        },
        generalLoading: true,
        pagesLoading: false,
        searching: false,
    };
};
export default {
    namespaced: true,
    state: initialState,
    getters: {
        getProductsServices(state) {
            return state.productsServices;
        },
        getSearchParams(state) {
            return state.searchParams;
        },
        anySearchParamsSet(state) {
            return (
                state.searchParams.current_page > 1 ||
                state.searchParams.data_search !== ""
            );
        },
        getExistingSearchResults(state) {
            return state.searchParams.existingSearchResults;
        },
    },
    mutations: {
        resetState(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                state[key] = s[key];
            });
        },
        setProductsServices(state, payload) {
            // loading is added to use it when it needs to do an action with one register from the pagination
            // like when the status is changed
            payload.productsServices.data.forEach(
                (productService) => (productService.loading = false)
            );

            state.productsServices = payload.productsServices;
            const currentPage = payload.productsServices.pagination.current_page,
                totalPages = payload.productsServices.pagination.total_pages;

            state.searchParams.pages = helper.pagination.pagesNavigationBar(
                currentPage,
                totalPages
            );
        },
        updateProductService(state, payload) {
            //Si state.productsServices es null es porque el cliente entro directamente a la url para editar y no se cargó la info desde la paginación
            if (!state.productsServices) return;

            //Se actualiza la información del producto / servicio editado y que se tiene guardado en memoria
            const indexProductServiceToUpdate = state.productsServices.data.findIndex(
                (productServiceMemory) =>
                    parseInt(productServiceMemory.id) ===
                    parseInt(payload.productServiceUpdated.id)
            );

            state.productsServices.data.splice(
                indexProductServiceToUpdate,
                1,
                payload.productServiceUpdated
            );
        },
        setNotReactiveInfo(state, payload) {
            //Esta función se usa al momento de editar un producto, para que se guarde las claves SAT en el navegador del cliente si es que cambian

            //condición en caso de que el usuario haya entrado directamente al producto y no se tenga la lista de productos y servicios
            if (state.productsServices) {
                const productService = payload.productService;
                const indexProductoServicioActualizar =
                    state.productsServices.data.findIndex(
                        (productServiceVuex) =>
                            parseInt(productServiceVuex.id) === parseInt(productService.id)
                    );

                state.productsServices.data[
                    indexProductoServicioActualizar
                    ].product_service_key = productService.productServiceObj.clave;
                state.productsServices.data[
                    indexProductoServicioActualizar
                    ].product_service_name = productService.productServiceObj.descripcion;
                state.productsServices.data[
                    indexProductoServicioActualizar
                    ].product_service_similar_words =
                    productService.productServiceObj.palabras_similares;

                state.productsServices.data[indexProductoServicioActualizar].unit_key =
                    productService.unitsObj.clave;
                state.productsServices.data[indexProductoServicioActualizar].unit_name =
                    productService.unitsObj.nombre;
                state.productsServices.data[
                    indexProductoServicioActualizar
                    ].unit_symbol = productService.unitsObj.simbolo;
            }
        },
        setExistingSearchResults(state, payload) {
            state.searchParams.existingSearchResults = payload.existingSearchResults;
        },
        resetUri: async function () {
            let page = router.currentRoute.query.page || 1;
            if (page > 1) {
                state.searchParams.current_page = 1;
                await router.replace({
                    name: "productsServices",
                    query: {page: "1"},
                });
            }
        },
        add(state, payload) {
            payload.productService.loading = false;
            if (state.productsServices) {
                state.productsServices.pagination.total++;
            }

            //si se encuentra en la primera página y no tiene filtros aplicados entonces se añade el registro
            if (
                state.searchParams.current_page === 1 &&
                state.searchParams.data_search === "" &&
                state.productsServices
            ) {
                //si la longitud del arreglo de los productServicees es = a los items mostrados por página
                //entonces se elimina el último resultado para no mostrar elementos de más
                if (
                    state.productsServices.data.length ===
                    state.searchParams.items_perpage
                ) {
                    state.productsServices.data.pop();
                }
                //Se añade el nuevo registro a la primera posición
                state.productsServices.data.unshift(payload.productService);
            }
        },
    },
    actions: {
        getAll({state, commit}, reload = false) {
            if (state.productsServices && !reload) {
                state.searchParams.current_page =
                    state.productsServices.pagination.current_page;
                return;
            }
            state.searchParams.current_page = parseInt(
                router.currentRoute.query.page || 1
            );
            return new Promise((resolve, reject) => {
                apiMunzen
                    .get(`/products-services?page=${state.searchParams.current_page}`)
                    .then((resp) => {
                        const productsServices = resp.data;
                        commit("setProductsServices", {productsServices});
                        resolve(true);
                    })
                    .catch((error) => {
                        console.log(error.response);
                        reject({
                            success: false,
                            statusCode: error.response.status,
                        });
                    })
                    .finally(() => {
                        state.generalLoading = false;
                    });
            });
        },
        getAllOrderByField(context, payload) {
            let field = "description";
            let order = "asc";
            let page = 1;

            if (payload !== undefined) {
                field = payload.field || field;
                order = payload.order || order;
                page = payload.page || page;
            }

            return new Promise((resolve, reject) => {
                apiMunzen
                    .get(
                        `/products-services?page=${page}&order_direction=${order}&order_by=${field}`
                    )
                    .then((resp) => {
                        const productsServices = resp.data;
                        resolve(productsServices);
                    })
                    .catch((error) => {
                        console.log(error.response);
                        reject({
                            success: false,
                            statusCode: error.response.status,
                        });
                    });
            });
        },
        getProductServiceInfo({state}, payload) {
            if (state.productsServices) {
                //Se retorna una copia del objeto encontrado para no alterar los datos originales
                return {
                    ...state.productsServices.data.find(
                        (productService) =>
                            parseInt(productService.id) === parseInt(payload.productServiceId)
                    ),
                };
            }

            return new Promise((resolve, reject) => {
                apiMunzen
                    .get(`/products-services/${payload.productServiceId}`)
                    .then((resp) => {
                        const productService = resp.data.data;
                        resolve(productService);
                    })
                    .catch((error) => {
                        reject({
                            success: false,
                            error: error.response,
                        });
                    });
            });
        },
        search({state, commit}, resetUri = true) {
            state.pagesLoading = true;
            state.searching = true;
            return new Promise((resolve, reject) => {
                apiMunzen
                    .post(
                        `/products-services/search?page=${state.searchParams.current_page}`,
                        state.searchParams
                    )
                    .then((resp) => {
                        const productsServices = resp.data;
                        commit("setProductsServices", {productsServices});
                        commit("setExistingSearchResults", {
                            existingSearchResults: productsServices.data.length > 0,
                        });
                        if (resetUri) {
                            commit("resetUri");
                        }
                        resolve(true);
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(false);
                    })
                    .finally(() => {
                        state.pagesLoading = false;
                        state.searching = false;
                    });
            });
        },
        searchProductsServicesWithoutPagination(context, searchValue) {
            const searchData = {
                data_search: searchValue,
                paginated_search: false,
            };
            return new Promise((resolve, reject) => {
                apiMunzen
                    .post(`/products-services/search`, searchData)
                    .then((resp) => {
                        const productsServices = resp.data.data;
                        resolve(productsServices);
                    })
                    .catch((error) => {
                        console.log(error);
                        reject(false);
                    });
            });
        },
        save({commit}, productService) {
            return new Promise((resolve, reject) => {
                apiMunzen
                    .post("/products-services", productService)
                    .then((resp) => {
                        const productService = resp.data.data;
                        commit("add", {productService});
                        resolve(productService);
                    })
                    .catch((error) => {
                        console.log(error.response);
                        reject({
                            success: false,
                            statusCode: error.response.status,
                        });
                    });
            });
        },
        updateProductService({commit}, productService) {
            return new Promise((resolve, reject) => {
                apiMunzen
                    .put(`/products-services/${productService.id}`, productService)
                    .then((resp) => {
                        const productServiceUpdated = resp.data.data;

                        commit("updateProductService", {productServiceUpdated});

                        resolve(productServiceUpdated);
                    })
                    .catch((error) => {
                        console.log(error.response);
                        reject({
                            success: false,
                            statusCode: error.response.status,
                        });
                    });
            });
        },
        delete(context, payload) {
            return new Promise((resolve, reject) => {
                apiMunzen
                    .delete(`/products-services/${payload.id}`)
                    .then(() => {
                        resolve({
                            success: true,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        reject({
                            success: false,
                            statusCode: error.response.status,
                        });
                    });
            });
        },
        exportProductsServices() {
            return new Promise((resolve, reject) => {
                apiMunzen
                    .get(`/products-services/export`, {
                        responseType: "blob",
                    })
                    .then((response) => {
                        FileSaver.saveAs(response.data, "Productos y Servicios");
                        resolve({
                            success: true,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        reject({
                            success: false,
                            statusCode: error.response.status,
                        });
                    });
            });
        },
        import({commit}, formData) {
            return new Promise((resolve, reject) => {
                apiMunzen
                    .post(`/products-services/import`, formData)
                    .then((response) => {
                        resolve(true);
                    })
                    .catch((error) => {
                        console.log("error products services import", error.response);
                        reject({
                            success: false,
                            statusCode: error.response.status,
                            message:
                                error.response.data.dataError ||
                                "error al importar los productos / servicios",
                        });
                    });
            });
        },
        importReport() {
            return new Promise((resolve, reject) => {
                apiMunzen
                    .get(`/products-services/import/report`)
                    .then((respose) => {
                        resolve(respose.data.data);
                    })
                    .catch((error) => {
                        console.log(
                            "error products services import report",
                            error.response
                        );
                        reject({
                            success: false,
                            statusCode: error.response.status,
                        });
                    });
            });
        },
        downloadProductsServicesImportTemplate() {
            return new Promise((resolve, reject) => {
                apiMunzen
                    .get(
                        `/products-service/import/download-import-products-services-template`,
                        {
                            responseType: "blob",
                        }
                    )
                    .then((response) => {
                        FileSaver.saveAs(
                            response.data,
                            "Plantilla para importar productos o servicios.xlsx"
                        );
                        resolve({
                            success: true,
                        });
                    })
                    .catch((error) => {
                        console.log(error);
                        reject({
                            success: false,
                            statusCode: error.response.status,
                        });
                    });
            });
        },
        checkIfIsTheLastElementInPagination({state, dispatch}) {
            if (state.productsServices.data.length === 0) {
                if (state.searchParams.current_page > 1) {
                    state.searchParams.current_page--;

                    dispatch("search", false);

                    router
                        .push({
                            name: "productsServices",
                            query: {page: state.searchParams.current_page.toString()},
                        })
                        .then();
                }
            }
        },
        uniqueInternalCode({}, data) {
            return new Promise((resolve, reject) => {
                apiMunzen
                    .post("/products-services/internal-code-unique", data)
                    .then((response) => {
                        resolve({
                            unique: response.data.is_unique,
                        });
                    })
                    .catch(() => {
                        reject({
                            error: error.response,
                        });
                    });
            });
        },
        uniqueDescription({}, data) {
            return new Promise((resolve, reject) => {
                apiMunzen
                    .post("/products-services/description-unique", data)
                    .then((response) => {
                        resolve({
                            unique: response.data.is_unique,
                        });
                    })
                    .catch(() => {
                        reject({
                            error: error.response,
                        });
                    });
            });
        },
    },
};
