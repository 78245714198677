import apiMunzen from "../../api/ApiMunzen";
import * as Sentry from "@sentry/vue";

export default {
  namespaced: true,

  state: {
    token: localStorage.getItem("accessToken") || null,
    user: JSON.parse(localStorage.getItem("user")) || null,
    enterprise: JSON.parse(localStorage.getItem("enterprise")) || null,
    totalRemainingCredits: null,
    previousUrlName: null,
  },

  getters: {
    user(state) {
      return state.user;
    },
    getAuthEnterprise: (state) => {
      return state.enterprise;
    },
    isAuthenticated: (state) => {
      return state.token !== null;
    },
    getEnterpriseName: (state) => {
      return state.enterprise.nombre_comercial
        ? state.enterprise.nombre_comercial
        : state.enterprise.razon_social;
    },
    getTotalRemainingCredits: (state) => {
      return state.totalRemainingCredits === 0 ? '0' : state.totalRemainingCredits;
    },
  },
  mutations: {
    resetState(state) {
      // acquire initial state
      state.enterprise = null;
      state.token = null;
      state.user = null;
    },
    setEnterprise(state, payload) {
      localStorage.removeItem("enterprise");
      state.enterprise = payload.enterprise;
      localStorage.setItem("enterprise", JSON.stringify(payload.enterprise));
    },
    login(state, payload) {
      if (payload.user) {
        state.user = payload.user;
        localStorage.setItem("user", JSON.stringify(payload.user));
        // Obtener la dirección IP del cliente
        const ipAddress = window.location.hostname;
        //Se establece información para conocer más sobre el usuario en Sentry
        //Al identificar al usuario que experimentó el error, Sentry puede proporcionar información más detallada sobre el error
        //en el contexto de ese usuario. Por ejemplo, Sentry puede mostrar la frecuencia con la que un usuario experimenta un error
        //específico, qué otras acciones ha realizado el usuario antes de que se produzca el error y en qué parte de la aplicación se produce el error.
        Sentry.setUser({
          id: payload.user.id,
          email: payload.user.email,
          ip_address: ipAddress,
        });
      }

      if (payload.expiresIn) {
        const now = new Date();
        const expirationDate = now.getTime() + payload.expiresIn * 1000;
        localStorage.setItem("expirationDate", expirationDate.toString());
      }

      state.token = payload.token;
      localStorage.setItem("accessToken", payload.token);
    },
    setTotalRemainingCredits(state, payload) {
      state.totalRemainingCredits = payload;
    },
    destroyLoginInfo(state) {
      state.user = null;
      state.token = null;
      state.totalRemainingCredits = null;

      localStorage.removeItem("accessToken");
      localStorage.removeItem("user");
      localStorage.removeItem("expirationDate");
      localStorage.removeItem("enterprise");
      localStorage.removeItem("preOrderPaymentGatewayID");

      Sentry.setUser(null);
    },
    resetUserLocalStorageData(state) {
      //Se restablece la información del usuario en el local storage con la que se tiene en el state user
      localStorage.removeItem("user");

      localStorage.setItem("user", JSON.stringify(state.user));
    },
  },
  actions: {
    login({ commit }, credentials) {
      commit("destroyLoginInfo");

      return new Promise((resolve) => {
        apiMunzen
          .post("/users/login", credentials)
          .then((resp) => {
            //if there is a new_client_token is because the user haven´t finish the initial config tutorial
            if (resp.data.new_client_token) {
              resolve({
                initialConfigCompleted: false,
                newClientToken: resp.data.new_client_token,
              });
            } else {
              commit("login", {
                user: resp.data.user,
                token: resp.data.token,
                expiresIn: resp.data.expires_in,
              });

              commit("setEnterprise", {
                enterprise: resp.data.user.enterprises[0],
              });
              resolve({ initialConfigCompleted: true });
            }
          })
          .catch(() => resolve(false));
      });
    },
    fetchUserTotalRemainingCredits({ commit }) {
      return new Promise((resolve) => {
        apiMunzen.get("/users/get-total-remaining-credits").then((resp) => {
          const totalRemainingCredits =
            resp.data.data.user_total_remaining_credits;
          commit("setTotalRemainingCredits", totalRemainingCredits);

          resolve({
            success: true,
            totalRemainingCredits,
          });
        });
      });
    },
    loginNewClient({ commit }, token) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .get(`/users/login/new-client/${token}`)
          .then((resp) => {
            commit("login", {
              user: resp.data.user,
              token: resp.data.token,
              expiresIn: resp.data.expires_in,
            });
            commit("setEnterprise", {
              enterprise: resp.data.user.enterprises[0],
            });
            resolve({ success: true });
          })
          .catch((error) => {
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    recoverPassword({ commit }, emailObject) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .post("/users/reset-password", emailObject)
          .then((resp) => {
            resolve({
              success: true,
            });
          })
          .catch((error) => {
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    existingResetPasswordToken({}, token) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .get(`/users/reset-password/exist/${token}`)
          .then(() => resolve(true))
          .catch(() => reject(false));
      });
    },
    setNewPassword({ commit }, payload) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .post("/users/reset-password/set", payload)
          .then((resp) => {
            commit("login", {
              user: resp.data.user,
              token: resp.data.token,
              expiresIn: resp.data.expires_in,
            });

            resolve(true);
          })
          .catch((error) => reject(error));
      });
    },
    register({}, registerData) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .post("/new-client-pre-register", registerData)
          .then((resp) => {
            resolve({
              token: resp.data.new_client_token,
            });
          })
          .catch((error) => {
            reject({
              error,
            });
          });
      });
    },
    logout(context) {
      if (context.getters.isAuthenticated) {
        return new Promise((resolve) => {
          apiMunzen
            .get("/users/logout")
            .then(() => {
              context.commit("destroyLoginInfo");
              resolve(true);
            })
            .catch((error) => {
              console.log(error);
              context.commit("destroyLoginInfo");
              resolve(true);
            });
        });
      }
    },
    uniqueEmail({}, email) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .get(`users/unique-email/${email}`)
          .then((resp) => {
            resolve({
              success: resp.data.success,
            });
          })
          .catch((error) => {
            reject({
              error: error.response,
            });
          });
      });
    },
    uniqueRfc({}, rfc) {
      return new Promise((resolve, reject) => {
        if (rfc === "") return true;

        return apiMunzen
          .get(`/enterprises/unique-rfc/${rfc}`)
          .then((resp) => {
            resolve({
              success: resp.data.success,
            });
          })
          .catch((error) => {
            reject({
              error: error.response,
            });
          });
      });
    },
    checkFirstStepsCompleted({ state, commit }) {
      if (state.user.first_steps_completed) return false;

      return new Promise((resolve, reject) => {
        apiMunzen
          .get("/users/check-first-steps-completed")
          .then((resp) => {
            const steps = resp.data.data;

            //Si todos los pasos están completos se actualiza el state del usuario
            if (
              steps.client_created &&
              steps.product_service_created &&
              steps.sellos_digitales_created &&
              steps.invoice_created
            ) {
              state.user.first_steps_completed = true;
              commit("resetUserLocalStorageData");
              resolve(false);
            } else {
              resolve(steps);
            }
          })
          .catch((error) => {
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
  },
};
