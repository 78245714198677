import Vue from "vue";
import VueRouter from "vue-router";
import auth from "../store/modules/Auth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      requiresAuth: true,
      title: "Inicio",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/auth/Login.vue"),
    meta: {
      requiresVisitor: true,
      title: "Login",
      metaTags: [
        {
          name: "description",
          content: "Inicio de sesión",
        },
        {
          property: "og:description",
          content: "Inicio de sesión",
        },
      ],
    },
  },
  {
    path: "/registro",
    name: "register",
    component: () => import("../views/auth/Register.vue"),
    meta: {
      requiresVisitor: true,
      title: "Registrate",
      metaTags: [
        {
          name: "description",
          content: "Registro",
        },
        {
          property: "og:description",
          content: "Registro",
        },
      ],
    },
  },
  {
    path: "/reset-password/:passwordResetToken?",
    name: "resetPassword",
    component: () => import("../views/auth/ResetPassword.vue"),
    props: true,
    meta: {
      requiresVisitor: true,
      title: "Restablecer contraseña",
    },
  },
  {
    path: "/bienvenido/:newClientToken",
    name: "welcome",
    props: true,
    component: () => import("../views/new_client/Welcome.vue"),
    meta: {
      title: "Bienvenido a Munzen",
    },
  },
  {
    path: "/configuracion-inicial/:newClientToken",
    name: "InitialConfiguration",
    props: true,
    component: () => import("../views/new_client/InitialConfiguration.vue"),
    meta: {
      title: "Configuración inicial",
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../components/LoginAndSignup/Logout.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/clientes",
    component: () => import("../views/clients/layouts/MainClient.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "clients",
        component: () => import("../views/clients/IndexClient.vue"),
        meta: {
          title: "Mis clientes",
        },
      },
      {
        path: "nuevo",
        name: "clients.create",
        component: () => import("../views/clients/CreateClient.vue"),
        meta: {
          title: "Clientes - Nuevo",
        },
      },
      {
        path: "editar/:id",
        name: "clients.edit",
        component: () => import("../views/clients/EditClient.vue"),
        meta: {
          title: "Clientes - Editar",
        },
        props: true,
      },
      {
        path: "importar",
        component: () =>
          import("../views/clients/layouts/ImportMainClient.vue"),
        children: [
          {
            path: "",
            name: "clients.import",
            component: () => import("../views/clients/Import/ImportClient.vue"),
            meta: {
              title: "Clientes - Importar",
            },
          },
          {
            path: "reporte",
            name: "clients.import.report",
            component: () =>
              import("../views/clients/Import/ImportReportClient.vue"),
            meta: {
              title: "Clientes - Reporte de importación",
            },
          },
          {
            path: "usos-cfdi",
            name: "clients.import.usosCfdi",
            component: () =>
              import("../views/clients/Import/ImportUsosCFDIClient.vue"),
            meta: {
              title: "Clientes - Catálogo de usos de CFDI",
            },
          },
          {
            path: "formas-pago",
            name: "clients.import.formasPago",
            component: () =>
              import("../views/clients/Import/ImportFormasPagoClient.vue"),
            meta: {
              title: "Clientes - Catálogo de Formas de Pago",
            },
          },
          {
            path: "regimenes-fiscales",
            name: "clients.import.regimenesFiscales",
            component: () =>
              import(
                "../views/clients/Import/ImportRegimenesFiscalesClient.vue"
              ),
            meta: {
              title: "Clientes - Catálogo de Regímenes Fiscales",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/productos-servicios",
    component: () =>
      import("../views/products_services/layouts/MainProductService.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "productsServices",
        component: () =>
          import("../views/products_services/IndexProductService.vue"),
        meta: {
          title: "Mis productos / servicios",
        },
      },
      {
        path: "nuevo",
        name: "productsServices.create",
        component: () =>
          import("../views/products_services/CreateProductService.vue"),
        meta: {
          title: "Producto / Servicio - Nuevo",
        },
      },
      {
        path: "editar/:id",
        name: "productsServices.edit",
        component: () =>
          import("../views/products_services/EditProductService.vue"),
        meta: {
          title: "Producto / Servicio - Editar",
        },
        props: true,
      },
      {
        path: "importar",
        component: () =>
          import(
            "../views/products_services/layouts/ImportMainProductService.vue"
          ),
        children: [
          {
            path: "",
            name: "productsServices.import",
            component: () =>
              import(
                "../views/products_services/Import/ImportProductService.vue"
              ),
            meta: {
              title: "Productos / Servicios - Importar",
            },
          },
          {
            path: "catalogo-productos-servicios",
            name: "productsServices.import.catalogoProductosServicios",
            component: () =>
              import(
                "../views/products_services/CatalogoServiciosProductos.vue"
              ),
            meta: {
              title:
                "Productos / Servicios - Catálogo de productos y servicios SAT",
            },
          },
          {
            path: "catalogo-unidades-medida",
            name: "productsServices.import.catalogoUnidades",
            component: () =>
              import("../views/products_services/CatalogoUnidades.vue"),
            meta: {
              title: "Productos / Servicios - Catálogo de unidades SAT",
            },
          },
          {
            path: "reporte",
            name: "productsServices.import.report",
            component: () =>
              import(
                "../views/products_services/Import/ImportReportProductService.vue"
              ),
            meta: {
              title: "Productos / Servicios - Reporte de importación",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/configuraciones",
    component: () => import("../views/configs/layouts/MainConfigs.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "configs",
        component: () => import("../views/configs/ConfigsMenu.vue"),
        meta: {
          title: "Configuraciones",
        },
      },
      {
        path: "series-folios",
        name: "configs.seriesFolios",
        component: () =>
          import("../views/configs/series_folios/IndexSeriesFolios.vue"),
        meta: {
          title: "Series y Folios",
        },
      },
      {
        path: "sellos-certificados-digitales",
        name: "configs.sellosDigitales",
        component: () =>
          import("../views/configs/sellos_digitales/IndexSellosDigitales.vue"),
        meta: {
          title: "Sellos de los Certificados Digitales",
        },
      },
      {
        path: "mis-datos-fiscales",
        name: "configs.misDatosFiscales",
        component: () =>
          import(
            "../views/configs/mis_datos_fiscales/IndexMisDatosFiscales.vue"
          ),
        meta: {
          title: "Mis Datos Fiscales",
        },
      },
      {
        path: "grupos-de-impuestos",
        name: "configs.gruposImpuestos",
        component: () =>
          import("../views/configs/taxes_groups/IndexTaxesGroups.vue"),
        meta: {
          title: "Grupos de Impuestos",
        },
      },
    ],
  },
  {
    path: "/facturacion",
    component: () => import("../views/facturacion/layouts/MainFacturacion.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "facturacion",
        component: () => import("../views/facturacion/FacturacionMenu.vue"),
        meta: {
          title: "Facturación",
        },
      },
      {
        path: "facturas",
        component: () =>
          import("../views/facturacion/facturas/layouts/MainFactura.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "",
            name: "facturacion.facturas",
            component: () =>
              import("../views/facturacion/facturas/Index/IndexFactura.vue"),
            meta: {
              title: "Mis Facturas",
            },
          },
          {
            path: "nuevo",
            name: "facturacion.facturas.create",
            component: () =>
              import("../views/facturacion/facturas/CreateFactura.vue"),
            meta: {
              title: "Factura - Nueva",
            },
          },
          {
            path: "detalle/:facturaId",
            name: "facturacion.facturas.detail",
            component: () =>
              import("../views/facturacion/facturas/DetailFactura.vue"),
            meta: {
              title: "Factura - Detalle",
            },
          },
          {
            path: "editar/:facturaId",
            name: "facturacion.facturas.edit",
            component: () =>
              import("../views/facturacion/facturas/EditFactura.vue"),
            meta: {
              title: "Factura - Editar",
            },
          },
          {
            path: "clonar/:facturaId",
            name: "facturacion.facturas.clone",
            component: () =>
              import("../views/facturacion/facturas/CloneFactura.vue"),
            meta: {
              title: "Factura - Clonar",
            },
          },
        ],
      },
      {
        path: "facturas-globales",
        component: () =>
          import("../views/facturacion/facturas_globales/layouts/MainFacturaGlobal.vue"),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "",
            name: "facturacion.facturas-globales",
            component: () =>
              import("../views/facturacion/facturas_globales/Index/IndexFacturaGlobal.vue"),
            meta: {
              title: "Mis Facturas Globales",
            },
          },
          {
            path: "nuevo/:month/:year",
            name: "facturacion.facturas-globales.create",
            component: () =>
              import("../views/facturacion/facturas_globales/CreateFacturaGlobal.vue"),
            meta: {
              title: "Factura Global - Nuevo",
            },
          },
          {
            path: "detalle/:facturaGlobalId",
            name: "facturacion.facturas-globales.detail",
            component: () =>
              import("../views/facturacion/facturas_globales/DetailFacturaGlobal.vue"),
            meta: {
              title: "Factura Global - Detalle",
            },
          },
          {
            path: "editar/:facturaGlobalId",
            name: "facturacion.facturas-globales.edit",
            component: () =>
              import("../views/facturacion/facturas_globales/EditFacturaGlobal.vue"),
            meta: {
              title: "Factura Global - Editar",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/comprar-creditos",
    component: () =>
      import("../views/user/buy_credits/layouts/MainCredits.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "buyCredits",
        component: () => import("../views/user/buy_credits/BuyCredits.vue"),
        meta: {
          title: "Comprar Créditos",
        },
      },
      {
        path: "check-out",
        name: "buyCredits.checkOut",
        component: () => import("../views/user/buy_credits/CheckOut.vue"),
        meta: {
          title: "Compra Realizada",
        },
      },
    ],
  },
  {
    path: "/mis-compras-de-creditos",
    component: () =>
      import("../views/user/my_credits_orders/layouts/MainMyCreditsOrders.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "myCreditsOrders",
        component: () =>
          import("../views/user/my_credits_orders/IndexMyCreditsOrders.vue"),
        meta: {
          title: "Mis Compras de Créditos",
        },
      },
      {
        path: "detalle/:id",
        name: "myCreditsOrders.detail",
        component: () =>
          import("../views/user/my_credits_orders/DetailMyCreditOrder.vue"),
        meta: {
          title: "Mis Compras de Créditos - Detalle",
        },
      },
    ],
  },
  {
    path: "/historial-de-gastos-de-creditos",
    component: () =>
      import(
        "../views/user/spending_credits_history/layouts/MainSpendingCreditsHistory.vue"
      ),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "spendingCreditsHistory",
        component: () =>
          import(
            "../views/user/spending_credits_history/IndexSpendingCreditsHistory.vue"
          ),
        meta: {
          title: "Historial de Gastos de Créditos",
        },
      },
    ],
  },
  {
    path: "/centro-de-ayuda",
    component: () =>
      import("../views/user/help_center/layouts/MainHelpCenter.vue"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        name: "helpCenter",
        component: () => import("../views/user/help_center/HelpCenter.vue"),
        meta: {
          title: "Centro de Ayuda",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: import.meta.env.BASE_URL,
  routes,
  /*scrollBehavior() {
    return { x: 0, y: 0 };
  },*/
});
router.beforeEach((to, from, next) => {
  //protected routes
  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (auth.state.token) {
      next();
    } else {
      // its used to redirect to previous url after login
      auth.state.previousUrlName = {
        name: to.name,
        params: to.params,
        query: to.query,
      };
      next({
        name: "login",
      });
    }
  } else if (to.matched.some((route) => route.meta.requiresVisitor)) {
    if (auth.state.token) {
      next({
        name: "home",
      });
    } else {
      next();
    }
  } else {
    next();
  }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el),
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));
});

export default router;
