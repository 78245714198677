import helper from "../../js/Helper";
import router from "../../router";
import apiMunzen from "../../api/ApiMunzen";
import { getField, updateField } from "vuex-map-fields";

let initialState = () => {
  return {
    facturasIngreso: null, //Contiene la información de las facturas en la paginación, no contiene toda la info de la factura
    consultedFacturas: [], //Aquí se guardarán las facturas consultadas para no llamarlas de nuevo al API, considerar al hacer eliminaciones
    currentShowFactura: null,
    searchParams: {
      filters: {
        //Al actualizar estos campos actualizar la función resetFilters
        rfc_name: null,
        from_date: null,
        to_date: null,
        serie: null,
        status: {
          text: "Todos",
          value: null,
        },
        items_per_page: helper.pagination.itemsPerPage,
        current_page: 1,
      },
      pages: [],
      items_per_page: helper.pagination.itemsPerPage,
      existingSearchResults: null,
      defaultPagesInPaginationBar: helper.pagination.pagesNavigationBarQuantity, //this is the number of showed pages the user can move in pagination bar
      recordsPerPage: helper.pagination.paginationSizeOptions,

      itemsSeries: [],
      itemsStatus: [
        {
          text: "Todos",
          value: null,
        },
        {
          text: "Canceladas",
          value: "cancelled",
        },
        {
          text: "Timbradas",
          value: "timbrada",
        },
        {
          text: "Pre Factura",
          value: "pre_factura",
        },
      ],
    },
    generalLoading: true,
    pagesLoading: false,
    searching: false,
    fetchingFactura: false,
    cancelingFactura: false,
    showWelcomePage: null, //Esta propiedad nos permite mostrar la primera pantalla cuando no se tiene ni una sola factura creada
  };
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getField,
    getFacturasIngreso(state) {
      return state.facturasIngreso;
    },
    getFacturasIngresoList(state) {
      return state.facturasIngreso.data;
    },
    getFacturasIngresoPaginationData(state) {
      return state.facturasIngreso.pagination;
    },
    getTotalPages(state) {
      return state.facturasIngreso.pagination.total_pages || 1;
    },
    getItemsStatusList(state) {
      return state.searchParams.itemsStatus;
    },
    getPagesLoading(state) {
      return state.pagesLoading;
    },
    getRecordsPerPage(state) {
      return state.searchParams.recordsPerPage;
    },
    getGeneralLoading(state) {
      return state.generalLoading;
    },
    getExistingSearchResults(state) {
      return state.searchParams.existingSearchResults;
    },
    getSearchParams(state) {
      return state.searchParams;
    },
    areFiltersApplied(state) {
      return areFiltersApplied(state);
    },
    getCurrentShowFactura(state) {
      return state.currentShowFactura;
    },
    getFetchingFactura(state) {
      return state.fetchingFactura;
    },
    getShowWelcomePage(state) {
      return state.showWelcomePage;
    },
    getCancelingFactura(state) {
      return state.cancelingFactura;
    },
  },
  mutations: {
    updateField,
    resetFilters(state) {
      state.searchParams.filters.rfc_name = null;
      state.searchParams.filters.from_date = null;
      state.searchParams.filters.to_date = null;
      state.searchParams.filters.serie = "Todas";
      state.searchParams.filters.status = {
        text: "Todos",
        value: null,
      };
      state.searchParams.filters.current_page = 1;
    },
    setFacturasIngreso(state, payload) {
      payload.facturasIngreso.data.forEach((facturaIngreso) => {
        setFacturaViewStatus(facturaIngreso);
      });

      state.facturasIngreso = payload.facturasIngreso;
      const currentPage = payload.facturasIngreso.pagination.current_page,
        totalPages = payload.facturasIngreso.pagination.total_pages;

      state.searchParams.pages = helper.pagination.pagesNavigationBar(
        currentPage,
        totalPages,
      );
    },
    resetUri: async function (state) {
      let page = router.currentRoute.query.page || 1;
      if (page > 1) {
        state.searchParams.filters.current_page = 1;
        await router.replace({
          name: "facturacion.facturas",
          query: { page: "1" },
        });
      }
    },
    setExistingSearchResults(state, payload) {
      //Al realizar una búsqueda se encontraron registros en caso contrario se muestra mensaje de registros no encontrados
      state.searchParams.existingSearchResults = payload.existingSearchResults;
    },
    setFalseGeneralLoading(state) {
      state.generalLoading = false;
    },
    incrementPaginationTotal(state) {
      //state.facturasIngreso puede no estar establecido si se entró directamente a la liga de creación de la factura
      //o si se recargó la página estando adentro
      if (state.facturasIngreso) {
        state.facturasIngreso.pagination.total++;
      }
    },
    decreasePaginationTotal(state) {
      //state.facturasIngreso puede no estar establecido si se entró directamente a la liga de creación de la factura
      //o si se recargó la página estando adentro
      if (state.facturasIngreso) {
        state.facturasIngreso.pagination.total--;
      }
    },
    addCreatedInvoice(state, facturaIngresoCreated) {
      setFacturaViewStatus(facturaIngresoCreated.list_item);

      //si se encuentra en la primera página y no tiene filtros aplicados entonces se añade el registro
      if (
        state.searchParams.filters.current_page === 1 &&
        state.facturasIngreso &&
        !areFiltersApplied(state)
      ) {
        //si la longitud del arreglo de las facturas es = a los items mostrados por página
        //entonces se elimina el último resultado para no mostrar elementos de más
        if (
          state.facturasIngreso.data.length ===
          state.searchParams.items_per_page
        ) {
          state.facturasIngreso.data.pop();
        }
        //Se añade el nuevo registro a la primera posición
        state.facturasIngreso.data.unshift(facturaIngresoCreated.list_item);
      }
    },
    appendToConsultedFacturas(state, factura) {
      //Es un arreglo con toda la información de las facturas consultadas, para no mandar hacer otro llamado al API
      state.consultedFacturas.push(factura);
    },
    removeFromConsultedFacturas(state, id) {
      //Se elimina el registro de la tabla de la paginación
      let index = state.consultedFacturas.findIndex(
        (consultedFacturaIngreso) => consultedFacturaIngreso.id === id,
      );

      if (index !== -1) {
        state.consultedFacturas.splice(index, 1);
      }
    },
    resetConsultedFacturas(state) {
      state.consultedFacturas = [];
    },
    setCurrentFactura(state, factura) {
      //Se establece la factura que va a ser consultada, comúnmente esta factura se obtiene desde el arreglo
      //de consultedFacturas
      //Esto se hace para que pueda ser consultada desde diferentes componentes
      state.currentShowFactura = factura;

      setFacturaViewStatus(state.currentShowFactura);

      state.fetchingFactura = false;
    },
    updateFactura(state, factura) {
      //Se actualiza la factura que se encuentra en el arreglo de consultedFacturas
      //y la info de la factura que se está consultando en la vista detalle
      state.currentShowFactura = factura;
      setFacturaViewStatus(state.currentShowFactura);

      //Se actualiza la factura en el arreglo de consultedFacturas
      let index = state.consultedFacturas.findIndex(
        (consultedFacturaIngreso) => consultedFacturaIngreso.id === factura.id,
      );
      if (index !== -1) {
        state.consultedFacturas[index] = factura;
      }
      //Se actualiza el registro en la tabla de la paginación
      if (state.facturasIngreso) {
        let index = state.facturasIngreso.data.findIndex(
          (facturaIngreso) => facturaIngreso.id === factura.id,
        );
        if (index !== -1) {
          state.facturasIngreso.data[index].status = factura.status;
          state.facturasIngreso.data[index].view_status = factura.view_status;
          setFacturaViewStatus(state.facturasIngreso.data[index]);
        }
      }
    },
    removeFacturaRow(state, id) {
      if (state.facturasIngreso) {
        //Se elimina el registro de la tabla de la paginación
        let index = state.facturasIngreso.data.findIndex(
          (facturaIngreso) => facturaIngreso.id === id,
        );

        if (index !== -1) {
          state.facturasIngreso.data.splice(index, 1);
        }
      }
    },
    setShowWelcomePage(state, show) {
      state.showWelcomePage = show;
    },
    removeConsultedFacturas(state) {
      state.consultedFacturas = [];
    },
    resetState(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    fetchFacturasIngreso({ state, commit }, reload = false) {
      if (state.facturasIngreso && !reload) {
        state.searchParams.filters.current_page =
          state.facturasIngreso.pagination.current_page;
        return;
      }
      state.searchParams.filters.current_page = parseInt(
        router.currentRoute.query.page || 1,
      );

      return new Promise((resolve, reject) => {
        apiMunzen
          .get(
            `/facturacion/V40/facturas-ingreso?page=${state.searchParams.filters.current_page}`,
          )
          .then((resp) => {
            const facturasIngreso = resp.data;
            commit("setFacturasIngreso", { facturasIngreso });
            //validación en caso de que el usuario no tenga ninguna factura creada se muestre la pantalla de crear una
            commit("setExistingSearchResults", {
              existingSearchResults:
                facturasIngreso.data.length > 0 ? true : null,
            });
            commit(
              "setShowWelcomePage",
              facturasIngreso.pagination.total === 0,
            );
            resolve(true);
          })
          .catch((error) => {
            console.log(error.response);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    fetchFacturaIngreso({ state, commit }, facturaId) {
      state.fetchingFactura = true;

      //búsqueda para saber si la factura está en las facturas que ya se han consultado
      const factura = state.consultedFacturas.find(
        (consultedFactura) =>
          parseInt(consultedFactura.id) === parseInt(facturaId),
      );
      if (factura) {
        commit("setCurrentFactura", factura);
        return factura;
      }

      return new Promise((resolve, reject) => {
        apiMunzen
          .get(`/facturacion/V40/facturas-ingreso/${facturaId}`)
          .then((resp) => {
            const facturaFetched = resp.data.data;
            commit("appendToConsultedFacturas", facturaFetched);
            commit("setCurrentFactura", facturaFetched);
            resolve(facturaFetched);
          })
          .catch((error) => {
            reject({
              success: false,
              error: error.response,
            });
          });
      });
    },
    searchFacturas({ state, commit }, resetUri = true) {
      state.pagesLoading = true;
      state.searching = true;

      //Se preparan los campos con los filtros que pide el API
      let searchData = structuredClone(state.searchParams.filters);

      searchData.status = state.searchParams.filters.status.value;

      if (state.searchParams.itemsSeries.length === 1) {
        //Si solo se tiene una serie no es necesario enviar el nombre de esa serie en el back, eso hará que la consulta omita este filtro
        //y la búsqueda sea más rápida
        searchData.series = null;
      } else {
        searchData.series =
          state.searchParams.filters.serie === "Todas"
            ? null
            : [state.searchParams.filters.serie];
      }

      return new Promise((resolve, reject) => {
        apiMunzen
          .post(
            `/facturacion/V40/facturas-ingreso/search?page=${state.searchParams.filters.current_page}`,
            searchData,
          )
          .then((resp) => {
            const facturasIngreso = resp.data;
            commit("setFacturasIngreso", { facturasIngreso });
            commit("setExistingSearchResults", {
              existingSearchResults: facturasIngreso.data.length > 0,
            });
            commit("setShowWelcomePage", false);
            if (resetUri) {
              commit("resetUri");
            }
            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            reject(false);
          })
          .finally(() => {
            state.pagesLoading = false;
            state.searching = false;
          });
      });
    },
    async checkIfIsTheLastElementInPagination({ state, commit, dispatch }) {
      if (state.facturasIngreso.data.length === 0) {
        if (state.searchParams.current_page > 1) {
          state.searchParams.current_page--;

          dispatch("searchFacturas", false);

          router
            .push({
              name: "facturacion.facturas",
              query: { page: state.searchParams.current_page.toString() },
            })
            .then();
        } else {
          //Si no hay más registros pero estamos en la página 1

          //1 Se verifica si existen filtros aplicados para resetearlos y buscar de nuevo todas las facturas
          //si la búsqueda sigue trayendo cero resultados es porque se eliminaron todas las facturas y se debe mostrar la pantalla de bienvenida

          if (areFiltersApplied(state)) {
            commit("resetFilters");

            //Se restablece el valor que debe tener el filtro de las series
            const fetchedSeries = await dispatch(
              "SeriesFolios/fetchFacturasIngresoSeries",
              null,
              { root: true },
            );

            state.searchParams.itemsSeries = [];
            if (fetchedSeries.length === 1) {
              state.searchParams.filters.serie = fetchedSeries[0].serie;
              state.searchParams.itemsSeries.push(fetchedSeries[0].serie);
            } else {
              //Cuando se tiene más de una serie se añade la opción todas, haciendo referencia a que se están mostrando todas las series en la paginación
              state.searchParams.itemsSeries.push("Todas");
              fetchedSeries.forEach((value) => {
                state.searchParams.itemsSeries.push(value.serie);
              });
              //Por si no se ha definido un valor para la serie
              if (!state.searchParams.filters.serie) {
                state.searchParams.filters.serie = "Todas";
              }
            }

            //Se buscan de nuevo facturas
            await dispatch("searchFacturas");

            //Si aún después de realizar la búsqueda no quedan más facturas es porque fue el último registro
            if (state.facturasIngreso.data.length === 0) {
              commit("setShowWelcomePage", true);
              commit("setExistingSearchResults", {
                existingSearchResults: true,
              });
            }
          } else {
            //Si se aplicaron filtros y estamos en la última página y ya no hay más registros se muestra la página de bienvenido
            commit("setShowWelcomePage", true);
            commit("setExistingSearchResults", { existingSearchResults: true });
          }
        }
      }
    },
    sendFacturaByEmail(...payload) {
      const data = payload[1];
      return new Promise((resolve, reject) => {
        apiMunzen
          .post(
            `/facturacion/V40/facturas-ingreso/${data.facturaId}/send-by-email`,
            {
              emails: data.emails,
            },
          )
          .then(() => resolve(true))
          .catch((error) => {
            reject({
              success: false,
              error: error.response,
            });
          });
      });
    },
    sendPreFacturaByEmail(...payload) {
      const data = payload[1];
      return new Promise((resolve, reject) => {
        apiMunzen
          .post(
            `/facturacion/V40/pre-facturas-ingreso/${data.facturaId}/send-by-email`,
            {
              emails: data.emails,
            },
          )
          .then(() => resolve(true))
          .catch((error) => {
            reject({
              success: false,
              error: error.response,
            });
          });
      });
    },
  },
};

function areFiltersApplied(state) {
  let serieCondition;

  if (
    state.searchParams.itemsSeries.length === 1 ||
    state.facturasIngreso.data.length === 0
  ) {
    //Si solo se tiene una serie o si es la primera factura que se crea no es necesario mostrar el botón de limpiar
    serieCondition = false;
  } else {
    serieCondition = state.searchParams.filters.serie !== "Todas";
  }

  return !!(
    state.searchParams.filters.from_date ||
    state.searchParams.filters.rfc_name ||
    state.searchParams.filters.to_date ||
    serieCondition ||
    state.searchParams.filters.status.value
  );
}

function setFacturaViewStatus(factura) {
  if (factura.status === "timbrada") {
    factura.icon = "fas fa-stamp";
    factura.color = "teal";
    factura.textColor = "white";
  } else if (factura.status === "pre_factura") {
    factura.icon = "fa-solid fa-file-pen";
    factura.color = "blue";
    factura.textColor = "white";
  } else if (factura.status === "cancelled") {
    factura.icon = "fa-solid fa-file-circle-xmark";
    factura.color = "red";
    factura.textColor = "white";
  }
}
