import apiMunzen from "../../api/ApiMunzen";

let initialState = () => {
  return {
    newClientToken: "",
    newClientInfo: {},
    stepScreen: 1,
    enterpriseInfo: {},
  };
};

export default {
  namespaced: true,
  state: initialState,

  getters: {
    getNewClientInfo(state) {
      return state.newClientInfo;
    },
    getEnterpriseInfo(state) {
      return state.enterpriseInfo;
    },
  },

  mutations: {
    resetState(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    setClientToken(state, payload) {
      state.newClientToken = payload;
    },

    setClientInfo(state, payload) {
      state.newClientInfo = payload;
    },

    setEnterpriseInfo(state, payload) {
      state.enterpriseInfo = payload;
    },

    nextStepScreen(state) {
      state.stepScreen++;
    },
    backStepScreen(state) {
      state.stepScreen--;
    },
    destroyInitialConfigurationInfo(state) {
      state.newClientToken = "";
      state.newClientInfo = {};
      state.stepScreen = 1;
      state.enterpriseInfo = {};
    },
  },
  actions: {
    validateAccountVerificationToken({ rootState }, payload) {
      const data = {
        new_client_token: rootState.InitialConfig.newClientToken,
        email_verification_token: payload.accountVerificationToken,
      };

      return new Promise((resolve, reject) => {
        apiMunzen
          .post("new-client/validate-token", data)
          .then(() => resolve(true))
          .catch((error) => {
            reject({
              success: false,
              error: error.response,
            });
          });
      });
    },
    resendAccountVerificationToken({ rootState }) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .get(
            `new-client/resend-pre-register-email/${rootState.InitialConfig.newClientToken}`
          )
          .then(() => resolve(true))
          .catch((error) => {
            reject({
              success: false,
              error: error.response,
            });
          });
      });
    },
    changeRegisteredEmail({ rootState }, payload) {
      const data = {
        new_client_token: rootState.InitialConfig.newClientToken,
        email: payload.email,
      };

      return new Promise((resolve, reject) => {
        apiMunzen
          .post(`new-client/change-email`, data)
          .then(() => resolve(true))
          .catch((error) => {
            reject({
              success: false,
              error: error.response,
            });
          });
      });
    },
  },
};
