if (process.env.NODE_ENV === 'production') {
  // Obtén el usuario del Local Storage
  let user = localStorage.getItem('user');
  let userInfo;

  // Verifica si el usuario existe en el Local Storage
  if (user) {
    // Si el usuario existe, conviértelo a un objeto JavaScript
    user = JSON.parse(user);
    const enterprise = JSON.parse(localStorage.getItem('enterprise'));
    userInfo = {
      userId: user.email,
      userName: user.name,
      rfc: enterprise.rfc,
      enterprise: enterprise.razon_social,
    };
  }

  // Código para cargar el script de HubSpot
/*  const scriptHubSpot = document.createElement('script');
  scriptHubSpot.type = 'text/javascript';
  scriptHubSpot.id = 'hs-script-loader';
  scriptHubSpot.async = true;
  scriptHubSpot.defer = true;
  scriptHubSpot.src = '//js.hs-scripts.com/45465579.js';
  document.body.appendChild(scriptHubSpot);*/

  // Código para cargar el primer script de Google Tag Manager (google analytics)
  /*const scriptGTag1 = document.createElement('script');
  scriptGTag1.src = 'https://www.googletagmanager.com/gtag/js?id=G-C95V057NZS';
  scriptGTag1.async = true;
  document.body.appendChild(scriptGTag1);*/

  // Código para cargar el segundo script de Google Tag Manager (google ads)
/*  const scriptGTag2 = document.createElement('script');
  scriptGTag2.src = 'https://www.googletagmanager.com/gtag/js?id=AW-16507430486';
  scriptGTag2.async = true;
  document.body.appendChild(scriptGTag2);*/

  // Código para ejecutar los scripts de Google Tag Manager  (Conversiones de google ads)
/*  const inlineScript = document.createElement('script');
  inlineScript.textContent = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-C95V057NZS');
    gtag('config', 'AW-16507430486');
    gtag('event', 'conversion', {'send_to': 'AW-16507430486/5ZzuCOe94Z8ZENbMrb89'});
  `;
  document.body.appendChild(inlineScript);*/

  // Se carga el script para session rewind
  const scriptSessionRewind = document.createElement('script');
  scriptSessionRewind.textContent = `
    !function (o) {
      var w = window;
      w.SessionRewindConfig = o;
      var f = document.createElement("script");
      f.async = 1, f.crossOrigin = "anonymous",
      f.src = "https://rec.sessionrewind.com/srloader.js";
      var g = document.getElementsByTagName("head")[0];
      g.insertBefore(f, g.firstChild);
    }({
      apiKey: 'fIttkX78Iv1kCXxdBxN4hakzmmuJTd432H8Fxo9k',
      startRecording: true,
      ${userInfo ? `userInfo: ${JSON.stringify(userInfo)},` : ''}
    });
  `;
  document.body.appendChild(scriptSessionRewind);
}