import apiMunzen from "../../api/ApiMunzen";
import { getField, updateField } from "vuex-map-fields";
import helper from "../../js/Helper";

let initialState = () => {
  return {
    loadingInfo: true,
    savingData: false,
    datosFiscalesFormData: {
      razon_social: "",
      nombre_comercial: "",
      regimen_fiscal: "",
      codigo_postal: "",
      estado: "",
      ciudad: "",
      municipio: "",
      colonia: "",
      calle: "",
      numero_exterior: "",
      numero_interior: "",
    },
  };
};
export default {
  namespaced: true,
  state: initialState,
  getters: {
    getField,
    loadingInfo(state) {
      return state.loadingInfo;
    },
    savingData(state) {
      return state.savingData;
    },
    getDatosFiscalesInfo(state) {
      return state.datosFiscalesFormData;
    },
  },
  mutations: {
    updateField,
    setDatosFiscalesInfo(state, payload) {
      state.datosFiscalesFormData = payload;
    },
    disableLoadingInfo(state) {
      state.loadingInfo = false;
    },
    enableSavingData(state) {
      state.savingData = true;
    },
    disableSavingData(state) {
      state.savingData = false;
    },
    setLogoInLocalStorage(state, payload) {
      if (localStorage.getItem("enterprise")) {
        let enterprise = JSON.parse(localStorage.getItem("enterprise"));
        enterprise.logo = payload;
        localStorage.setItem("enterprise", JSON.stringify(enterprise));
      }
    },
    removeLogoInLocalStorage() {
      if (localStorage.getItem("enterprise")) {
        let enterprise = JSON.parse(localStorage.getItem("enterprise"));
        delete enterprise.logo;
        localStorage.setItem("enterprise", JSON.stringify(enterprise));
      }
    },
    resetState(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    getDatosFiscalesInitialConfiguration({ commit, rootState }) {
      return new Promise((resolve) => {
        apiMunzen
          .get(
            `/new-client/datos-fiscales/${rootState.InitialConfig.newClientToken}`,
          )
          .then((response) => {
            commit("setDatosFiscalesInfo", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            console.log(error.response);
            resolve(false);
          });
      });
    },
    updateDatosFiscalesInitialConfiguration({ state, rootState }) {
      return new Promise((resolve) => {
        apiMunzen
          .put(
            `new-client/datos-fiscales/${rootState.InitialConfig.newClientToken}`,
            state.datosFiscalesFormData,
          )
          .then((response) => {
            const datosFiscalesUpdated = response.data.data;
            console.log(response);
            resolve(datosFiscalesUpdated);
          })
          .catch(() => resolve(false));
      });
    },
    fetchDatosFiscales({ commit, rootState }) {
      return new Promise((resolve) => {
        apiMunzen
          .get(`/datos-fiscales/${rootState.Auth.enterprise.id}`)
          .then((response) => {
            commit("setDatosFiscalesInfo", response.data.data);
            resolve(true);
          })
          .catch((error) => {
            console.log(error.response);
            resolve(false);
          });
      });
    },
    updateDatosFiscales({ state, rootState }) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .put(
            `/datos-fiscales/${rootState.Auth.enterprise.id}`,
            state.datosFiscalesFormData,
          )
          .then((response) => resolve(response.data.data))
          .catch((error) => {
            console.log(error.response);
            reject(false);
          });
      });
    },
    async storeUpdateLogo({ rootState }, $base64Logo) {
      const blobLogo = await helper.base64ToBlob($base64Logo);

      const formData = new FormData();
      formData.append("logo", blobLogo, "crop_image.jpg");

      return new Promise((resolve, reject) => {
        apiMunzen
          .post(
            `/datos-fiscales/${rootState.Auth.enterprise.id}/logo`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          )
          .then((response) => resolve(response.data.data.logo))
          .catch((error) => {
            console.log(error.response);
            reject(false);
          });
      });
    },
    async storeUpdateLogoInitialConfiguration({ rootState }, $base64Logo) {
      const blobLogo = await helper.base64ToBlob($base64Logo);

      const formData = new FormData();
      formData.append("logo", blobLogo, "crop_image.jpg");
      return new Promise((resolve) => {
        apiMunzen
          .post(
            `new-client/datos-fiscales/${rootState.InitialConfig.newClientToken}/logo`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            },
          )
          .then((response) => resolve(response.data.data.logo))
          .catch(() => resolve(false));
      });
    },
    removeLogo({ rootState }) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .delete(`/datos-fiscales/${rootState.Auth.enterprise.id}/logo`)
          .then(() => resolve(true))
          .catch((error) => {
            console.log(error.response);
            reject(false);
          });
      });
    },
    removeLogoInitialConfiguration({ rootState }) {
      return new Promise((resolve) => {
        apiMunzen
          .delete(
            `new-client/datos-fiscales/${rootState.InitialConfig.newClientToken}/logo`,
          )
          .then(() => resolve(true))
          .catch(() => resolve(false));
      });
    },
  },
};
