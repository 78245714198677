import helper from "../../js/Helper";
import apiMunzenCatalogos from "../../api/ApiMunzenCatalogos";

let initialState = () => {
  return {
    allMexicanStates: [],
    mexicanState: {
      clave: null,
      estado: null,
    },
    regimenesFiscales: [],
    allRegimenesFiscales: [], //Se almacenan todos los regímenes fiscales
    objetosImpuesto: [],
    usosCfdi: [],
    allUsosCfdi: [], //Se almacenan todos los usos de cfdi
    formasPago: [],
    metodosPago: [],
    motivosCancelacion: [],
    countries: [],
    addressInfoByCP: {
      codigoPostal: null,
      claveEstado: null,
      claveMunicipio: null,
      claveLocalidad: null,
      estimuloFranjaFronteriza: null,
      referenciasHusoHorario: null,
    },
    productsServices: {
      data: [],
      pagination: {
        totalItems: 0,
        page: 1,
        itemsPerPage: helper.pagination.itemsPerPage,
        totalPages: 0,
      },
    },
    units: {
      data: [],
      pagination: {
        totalItems: 0,
        page: 1,
        itemsPerPage: helper.pagination.itemsPerPage,
        totalPages: 0,
      },
    },
    mostUsedUnits: [],
  };
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getRegimenesFiscalesList(state) {
      //Retorna la lista de régimenes fiscales conforme a la personalidad del RFC
      return state.regimenesFiscales.map(function (regimenFiscal) {
        return {
          text: `(${regimenFiscal.clave}) ${regimenFiscal.descripcion}`,
          value: regimenFiscal.clave,
        };
      });
    },
    getAllRegimenesFiscalesList(state) {
      //Retorna la lista de régimenes fiscales conforme a la personalidad del RFC
      return state.allRegimenesFiscales.map(function (regimenFiscal) {
        return {
          text: `(${regimenFiscal.clave}) ${regimenFiscal.descripcion}`,
          value: regimenFiscal.clave,
        };
      });
    },
    getAllMexicanStatesList(state) {
      //Retorna la lista de estados de la república mexicana
      return state.allMexicanStates.map(function (state) {
        return {
          text: state.estado,
          value: state.clave,
        };
      });
    },
    getObjetosImpuestoList(state) {
      //Retorna la lista de objetos de impuesto
      return state.objetosImpuesto.map(function (objetoImpuesto) {
        return {
          text: `(${objetoImpuesto.clave}) ${objetoImpuesto.descripcion}`,
          value: objetoImpuesto.clave,
        };
      });
    },
    findObjetoImpuestoByKey: (state) => (key) => {
      return state.objetosImpuesto.find(
        (objetoImpuesto) => objetoImpuesto.clave === key,
      );
    },
    getUsosCfdiList(state) {
      //Retorna la lista de usos cfdi
      return state.usosCfdi.map(function (usoCfdi) {
        return {
          text: `(${usoCfdi.clave}) ${usoCfdi.descripcion}`,
          value: usoCfdi.clave,
        };
      });
    },
    getAllUsosCfdiList(state) {
      //Retorna la lista de usos cfdi
      return state.allUsosCfdi.map(function (usoCfdi) {
        return {
          text: `(${usoCfdi.clave}) ${usoCfdi.descripcion}`,
          value: usoCfdi.clave,
        };
      });
    },
    getFormasPagoList(state) {
      //Retorna la lista de objetos de impuesto
      return state.formasPago.map(function (formaPago) {
        return {
          text: `(${formaPago.clave}) ${formaPago.descripcion}`,
          value: formaPago.clave,
        };
      });
    },
    getMetodosPagoList(state) {
      //Retorna la lista de métodos de pago del SAT
      return state.metodosPago.map(function (metodoPago) {
        return {
          text: `(${metodoPago.clave}) ${metodoPago.descripcion}`,
          value: metodoPago.clave,
        };
      });
    },
    getMotivosCancelacionList(state) {
      //Retorna la lista de motivos de cancelación del SAT
      return state.motivosCancelacion.map(function (motivoCancelacion) {
        return {
          text: `(${motivoCancelacion.clave}) ${motivoCancelacion.descripcion}`,
          value: motivoCancelacion.clave,
        };
      });
    },
    getCountriesList(state) {
      //Retorna la lista de objetos de impuesto
      return state.countries.map(function (country) {
        return {
          text: `(${country.clave}) ${country.descripcion}`,
          value: country.clave,
        };
      });
    },
    getAddressesInfoByCP(state) {
      return state.addressInfoByCP;
    },
  },
  mutations: {
    setRegimenesFiscales(state, payload) {
      state.regimenesFiscales = payload;
    },
    setAllRegimenesFiscales(state, payload) {
      state.allRegimenesFiscales = payload;
    },
    setObjetosImpuesto(state, payload) {
      //De momento se omiten los objetos de impuesto 03 y 04 que no son soportados en este momento
      state.objetosImpuesto = payload.filter(
        (objetoImpuesto) =>
          objetoImpuesto.clave === "01" ||
          objetoImpuesto.clave === "02" ,
      );
    },
    setAllMexicanStates(state, payload) {
      state.allMexicanStates = payload;
    },
    setUsosCfdi(state, payload) {
      state.usosCfdi = payload;
    },
    setAllUsosCfdi(state, payload) {
      state.allUsosCfdi = payload;
    },
    setFormasPago(state, payload) {
      state.formasPago = payload;
    },
    setMetodosPago(state, payload) {
      state.metodosPago = payload;
    },
    setMotivosCancelacion(state, payload) {
      //De momento se omiten los motivos de cancelación 01 y 04 que no son soportados en este momento
      state.motivosCancelacion = payload.filter(
        (motivoCancelacion) =>
          motivoCancelacion.clave !== "01" && motivoCancelacion.clave !== "04",
      );
    },
    setCountries(state, payload) {
      state.countries = payload;
    },
    setProductsServicesPagination(state, payload) {
      state.productsServices.pagination.totalItems = payload.total;
      state.productsServices.pagination.itemsPerPage = payload.per_page;
      state.productsServices.pagination.page = payload.current_page;
      state.productsServices.pagination.totalPages = payload.total_pages;
    },
    setProductsServices(state, payload) {
      //Aún no se define un uso como en los demás setters
      state.productsServices.data = payload;
    },
    setUnitsPagination(state, payload) {
      state.units.pagination.totalItems = payload.total;
      state.units.pagination.itemsPerPage = payload.per_page;
      state.units.pagination.page = payload.current_page;
      state.units.pagination.totalPages = payload.total_pages;
    },
    setUnits(state, payload) {
      //Aún no se define un uso como en los demás setters
      state.units.data = payload;
    },
    setMostUsedUnits(state, payload) {
      state.mostUsedUnits = payload;
    },
    setAddressInfoByCP(state, payload) {
      if (payload.length === 0)
        return (state.addressInfoByCP = initialState().addressInfoByCP);
      state.addressInfoByCP.codigoPostal = payload[0]["codigo_postal"];
      state.addressInfoByCP.claveEstado = payload[0]["clave_estado"];
      state.addressInfoByCP.claveMunicipio = payload[0]["clave_municipio"];
      state.addressInfoByCP.claveLocalidad = payload[0]["clave_localidad"];
      state.addressInfoByCP.estimuloFranjaFronteriza =
        payload[0]["estimulo_franja_fronteriza"];
      state.addressInfoByCP.referenciasHusoHorario =
        payload[0]["referencias_huso_horario"];
    },
    setMexicanState(state, payload) {
      if (payload === null)
        return (state.mexicanState = initialState().mexicanState);
      state.mexicanState.clave = payload.clave;
      state.mexicanState.estado = payload.estado;
    },
    resetState(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    fetchAllMexicanEstates({ commit }) {
      return new Promise((resolve, reject) => {
        apiMunzenCatalogos
          .get("/estados")
          .then((response) => {
            const states = response.data.data;
            commit("setAllMexicanStates", states);
            resolve(states);
          })
          .catch((error) => {
            console.log(error.response);
            reject(false);
          });
      });
    },
    fetchMexicanEstateByKey({ commit }, key) {
      return new Promise((resolve, reject) => {
        apiMunzenCatalogos
          .get(`/estados/${key}`)
          .then((response) => {
            const state = response.data.data;
            commit("setMexicanState", state);
            resolve(state);
          })
          .catch((error) => {
            console.log(error.response);
            reject(false);
          });
      });
    },
    fetchRegimenesFiscales({ commit }, rfc) {
      return new Promise((resolve, reject) => {
        apiMunzenCatalogos
          .get(`/regimenes-fiscales/${rfc}`)
          .then((response) => {
            const regimenesFiscales = response.data.data;
            commit("setRegimenesFiscales", regimenesFiscales);
            resolve(regimenesFiscales);
          })
          .catch((error) => {
            console.log(error.response);
            reject(false);
          });
      });
    },

    fetchAllRegimenesFiscales({ commit }) {
      return new Promise((resolve, reject) => {
        apiMunzenCatalogos
          .get(`/regimenes-fiscales`)
          .then((response) => {
            const allRegimenesFiscales = response.data.data;
            commit("setAllRegimenesFiscales", allRegimenesFiscales);
            resolve(allRegimenesFiscales);
          })
          .catch((error) => {
            console.log(error.response);
            reject(false);
          });
      });
    },

    fetchObjetosImpuesto({ commit, state }) {
      if (state.objetosImpuesto.length > 0) return state.objetosImpuesto;

      return new Promise((resolve, reject) => {
        apiMunzenCatalogos
          .get(`/objetos-impuesto`)
          .then((response) => {
            const objetosImpuesto = response.data.data;
            commit("setObjetosImpuesto", objetosImpuesto);
            resolve(objetosImpuesto);
          })
          .catch((error) => {
            console.log(error.response);
            reject(false);
          });
      });
    },

    fetchUsosCfdi({ commit }, data) {
      //Los usos de cfdi son diferentes conforme a cada regimen y personalidad

      return new Promise((resolve, reject) => {
        apiMunzenCatalogos
          .get(`/usos-cfdis/${data.rfc}/${data.regimenFiscal}`)
          .then((resp) => {
            const usosCfdi = resp.data.data;
            commit("setUsosCfdi", usosCfdi);
            resolve(usosCfdi);
          })
          .catch((error) => {
            console.log(error.response);
            reject({
              success: false,
            });
          });
      });
    },

    fetchAllUsosCfdi({ state, commit }) {
      if (state.allUsosCfdi.length > 0) return state.allUsosCfdi;

      return new Promise((resolve, reject) => {
        apiMunzenCatalogos
          .get("/usos-cfdis")
          .then((resp) => {
            const allUsosCfdi = resp.data.data;
            commit("setAllUsosCfdi", allUsosCfdi);
            resolve(allUsosCfdi);
          })
          .catch((error) => {
            console.log(error.response);
            reject({
              success: false,
            });
          });
      });
    },

    fetchFormasPago({ state, commit }) {
      if (state.formasPago.length > 0) return state.formasPago;

      return new Promise((resolve, reject) => {
        apiMunzenCatalogos
          .get(`/formas-pagos`)
          .then((resp) => {
            const formasPago = resp.data.data;
            commit("setFormasPago", formasPago);
            resolve(formasPago);
          })
          .catch((error) => {
            console.log(error.response);
            reject({
              success: false,
            });
          });
      });
    },

    fetchMetodosPago({ state, commit }) {
      if (state.metodosPago.length > 0) return state.metodosPago;

      return new Promise((resolve, reject) => {
        apiMunzenCatalogos
          .get(`/metodos-pago`)
          .then((resp) => {
            const metodosPago = resp.data.data;
            commit("setMetodosPago", metodosPago);
            resolve(metodosPago);
          })
          .catch((error) => {
            console.log(error.response);
            reject({
              success: false,
            });
          });
      });
    },

    fetchMotivosCancelacion({ state, commit }) {
      if (state.motivosCancelacion.length > 0) return state.motivosCancelacion;

      return new Promise((resolve, reject) => {
        apiMunzenCatalogos
          .get(`/motivos-cancelacion`)
          .then((resp) => {
            const motivosCancelacion = resp.data.data;
            commit("setMotivosCancelacion", motivosCancelacion);
            resolve(motivosCancelacion);
          })
          .catch((error) => {
            console.log(error.response);
            reject({
              success: false,
            });
          });
      });
    },

    fetchCountries({ state, commit }) {
      if (state.countries.length > 0) return state.countries;

      return new Promise((resolve, reject) => {
        apiMunzenCatalogos
          .get(`/paises`)
          .then((resp) => {
            const countries = resp.data.data;
            commit("setCountries", countries);
            resolve(countries);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },

    fetchProductsServices({ commit }, data) {
      return new Promise((resolve, reject) => {
        apiMunzenCatalogos
          .post("/productos-servicios/search?page=" + data.page, {
            data_search: data.searchValue,
          })
          .then((resp) => {
            const pagination = resp.data.pagination;
            const productsServicesData = resp.data.data;
            commit("setProductsServicesPagination", pagination);
            commit("setProductsServices", productsServicesData);
            resolve(productsServicesData);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },

    fetchUnits({ commit }, data) {
      return new Promise((resolve, reject) => {
        apiMunzenCatalogos
          .post("/unidades/search?page=" + data.page, {
            data_search: data.searchValue,
          })
          .then((resp) => {
            const pagination = resp.data.pagination;
            const UnitsData = resp.data.data;
            commit("setUnitsPagination", pagination);
            commit("setUnits", UnitsData);
            resolve(UnitsData);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },

    fetchMostUsedUnits({state, commit}) {
      if (state.mostUsedUnits.length > 0) {
        return state.mostUsedUnits;
      }

      return new Promise((resolve, reject) => {
        apiMunzenCatalogos
          .get("/unidades/most-used")
          .then((resp) => {
            const mostUsedUnitsDataResponse = resp.data.data;
            commit("setMostUsedUnits", mostUsedUnitsDataResponse);
            resolve(mostUsedUnitsDataResponse);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },

    fetchAddressInfoByCP({ commit }, cp) {
      if (cp.length !== 5 && !isNaN(cp))
        return Promise.reject({ success: false });

      const data = {
        codigo_postal: cp,
      };
      return new Promise((resolve, reject) => {
        apiMunzenCatalogos
          .post("/codigos-postales/search", data)
          .then((resp) => {
            commit("setAddressInfoByCP", resp.data.data);
            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    fetchLocations(_, payload) {
      return new Promise((resolve, reject) => {
        apiMunzenCatalogos
          .post("localidades/search", payload)
          .then((resp) => {
            resolve(resp.data.data);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    fetchMunicipalities(_, payload) {
      return new Promise((resolve, reject) => {
        apiMunzenCatalogos
          .post("municipios/search", payload)
          .then((resp) => {
            resolve(resp.data.data);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    fetchColoniesByCP(_, cp) {
      const data = {
        codigo_postal: cp,
      };
      return new Promise((resolve, reject) => {
        apiMunzenCatalogos
          .post("colonias/search", data)
          .then((resp) => {
            resolve(resp.data.data);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
  },
};
