import { getField, updateField } from "vuex-map-fields";
import apiMunzen from "../../api/ApiMunzen";
import Promise from "lodash/_Promise";

let initialState = () => {
  return {
    selectedPlan: {},
    paymentStep: 1,
    paymentExecutedResponse: null,
    preOrderPaymentGatewayResponse: null,
    stripeElements: null, //Objeto que contiene el secret_client con el que se va a realizar el pago
    stripeInstance: null,
    subscriptionPlans: [],
    oxxoDetails: null,
    promotionCode: {
      code: null,
      subscriptionPlanId: null,
      promoCredits: null,
    },
  };
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getField,
    getPreOrderPaymentGatewayInfo(state) {
      return state.preOrderPaymentGatewayResponse;
    },
    getStripeElements(state) {
      return state.stripeElements;
    },
    getSelectedPlan(state) {
      return state.selectedPlan;
    },
    getStripeInstance(state) {
      return state.stripeInstance;
    },
    getSubscriptionPlans(state) {
      return state.subscriptionPlans;
    },
    getOxxoDetails(state) {
      return state.oxxoDetails;
    },
    getPromotionCode(state) {
      return state.promotionCode;
    },
  },
  mutations: {
    updateField,
    setPreOrderPaymentGatewayInfo(state, payload) {
      localStorage.removeItem("preOrderPaymentGatewayID");
      state.preOrderPaymentGatewayResponse = payload;
      localStorage.setItem("preOrderPaymentGatewayID", payload.pre_order_id);
    },
    destroyPreOrderPaymentGatewayID(state) {
      //también se destruye el preOrderPaymentGatewayID si se cierra sesión
      //en Auth.js / destroyLoginInfo
      localStorage.removeItem("preOrderPaymentGatewayID");
      state.preOrderPaymentGatewayResponse = null;
      state.paymentStep = 1;
      state.selectedPlan = {};
    },
    setStripeElements(state, payload) {
      state.stripeElements = payload;
    },
    setStripeInstance(state, payload) {
      state.stripeInstance = payload;
    },
    setSubscriptionPlans(state, payload) {
      state.subscriptionPlans = payload;
    },
    setOxxoDetails(state, payload) {
      state.oxxoDetails = payload;
    },
    setPromotionCode(state, payload) {
      state.promotionCode.code = payload.code;
      state.promotionCode.subscriptionPlanId = payload.subscriptionPlanId;
      state.promotionCode.promoCredits = payload.promoCredits;
    },
    destroyPromotionCode(state) {
      state.promotionCode.code = null;
      state.promotionCode.subscriptionPlanId = null;
      state.promotionCode.promoCredits = null;
    },
    resetState(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    fetchPlans({ commit }) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .get("subscription-plans")
          .then((response) => {
            commit("setSubscriptionPlans", response.data.data);
            resolve(response.data.data);
          })
          .catch((error) => {
            console.log(error.response);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    setPreOrder({ commit }, payload) {
      const data = {
        subscription_plan_id: payload.subscription_plan_id,
        pre_order_id: localStorage.getItem("preOrderPaymentGatewayID") || null,
      };

      return new Promise((resolve, reject) => {
        apiMunzen
          .post("payments/credits/pre-order", data)
          .then((response) => {
            commit(
              "setPreOrderPaymentGatewayInfo",
              response.data.data.payment_gateway_response
            );
            resolve(response.data.data.payment_gateway_response);
          })
          .catch((error) => {
            console.log(error.response);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    reservePromotionalCode({ commit }, payload) {
      const data = {
        promotional_code: payload.promotionalCode,
        subscription_plan_id: payload.subscriptionPlanId,
      };
      return new Promise((resolve, reject) => {
        apiMunzen
          .post("promotional-codes/reserve", data)
          .then((response) => {
            const promotionalCodeData = {
              code: data.promotional_code,
              subscriptionPlanId: payload.subscription_plan_id,
              promoCredits: response.data.data.promo_credits,
            };

            commit("setPromotionCode", promotionalCodeData);
            resolve(response.data.data);
          })
          .catch((error) => {
            reject({
              apiErrorClientMessage: error.apiErrorClientMessage,
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    detachReservedPromotionalCodes({ commit }) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .post("promotional-codes/detach-reserved-codes")
          .then((response) => {
            commit("destroyPromotionCode");
            resolve(response.data.data);
          })
          .catch((error) => {
            console.log(error.response);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
  },
};
