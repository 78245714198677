export const taxesHelper = {
  /**
   * Retorna el nombre formateado del impuesto incluido el caso de que tenga una descripción adicional
   * como en el caso de IVA 10.666667% tienen una descripción adicional de IVA 2/3 partes
   * P/E de IVA .160000 se regresa "IVA 16%"
   * P/E de IEPS Cuota .265000 se regresa "IEPS Cuota 26.5%"
   *
   * @param tax
   * @returns {string}
   */
  formatTaxName(tax) {
    if (tax.tipo_factor === "exento") {
      return "IVA Exento";
    } else {
      let percentDescription;
      //Se hace este paso para corregir errores de precisión de js
      //En específico cuando se multiplica .07 por 100 da como resultado 7.000000000000001
      //Con este código se muestra como 7
      let percent = parseFloat((tax.value * 100).toFixed(6));
      if (Number.isInteger(percent)) {
        percentDescription = percent.toFixed(0) + "%";
      } else {
        percent = percent.toString().replace(/\.?0+$/, "");

        percentDescription = percent + "%";
      }

      //Se establece la descripción del impuesto trasladado o retenido,
      //ya que se puede tener una descripción adicional
      let taxDescription;
      if (tax.additional_description) {
        taxDescription = `${tax.additional_description} ${percentDescription}`;
      } else {
        taxDescription = `${tax.tax_type} ${percentDescription}`;
      }

      return taxDescription;
    }
  },
};
