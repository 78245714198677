import apiMunzen from "../../api/ApiMunzen";

let initialState = () => {
  return {
    impuestosRetenidos: {
      iva: {
        name: "IVA",
        taxes: [],
      },
      iepsCuota: {
        name: "IEPS (Cuota)",
        taxes: [],
      },
      iepsTasa: {
        name: "IEPS (Tasa)",
        taxes: [],
      },
      isr: {
        name: "ISR",
        taxes: [],
      },
    },
    taxesType: {
      ivaTaxesArray: null,
      iepsCuotaTaxesArray: null,
      iepsTasaTaxesArray: null,
      isrTaxesArray: null,
    },
  };
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getImpuestosRetenidos(state) {
      return state.impuestosRetenidos;
    },
    findIsrTaxByValue: (state) => (value) => {
      return state.impuestosRetenidos.isr.taxes.find(
        (tax) => tax.value === value,
      );
    },
    findIvaTaxByValue: (state) => (value) => {
      return state.impuestosRetenidos.iva.taxes.find(
        (tax) => tax.value === value,
      );
    },
    findTaxId: (state) => (taxToFind) => {
      //se busca él, id del impuesto retenido con base en el tipo de impuesto (IVA, ISR o IEPS) y el valor (0.16, 0.08, etc.)
      return state.impuestosRetenidos[taxToFind.taxType].taxes.find(
        (tax) => tax.value === taxToFind.value,
      ).id;
    },
  },
  mutations: {
    setAllImpuestosRetenidos(state, payload) {
      state.impuestosRetenidos.iva.taxes = payload.impuestosRetenidos.iva;

      state.impuestosRetenidos.iepsCuota.taxes =
        payload.impuestosRetenidos.ieps_cuota;

      state.impuestosRetenidos.iepsTasa.taxes =
        payload.impuestosRetenidos.ieps_tasa;

      state.impuestosRetenidos.isr.taxes = payload.impuestosRetenidos.isr;
    },
    resetTaxesType(state) {
      //this function is called when a taxe has benn enabled o disabled to force to refresh the
      //taxes form the API
      state.taxesType = {
        ivaTaxesArray: null,
        iepsCuotaTaxesArray: null,
        iepsTasaTaxesArray: null,
        isrTaxesArray: null,
      };
    },
    resetState(state) {
      // acquire initial state
      state.taxesType = {
        ivaTaxesArray: null,
        iepsCuotaTaxesArray: null,
        iepsTasaTaxesArray: null,
      };

      state.allImpuestosRetenidos = [];
    },
  },
  actions: {
    getAllImpuestosRetenidos(
      { state, commit, rootState },
      isInitialConfiguration = false,
    ) {
      //Si ya tenemos los impuestos retenidos en memoria del navegador no se vuelve a hacer la petición
      if (state.impuestosRetenidos.iva.taxes.length > 0) return;

      const url = isInitialConfiguration
        ? `new-client/impuestos-retenidos/${rootState.InitialConfig.newClientToken}`
        : "/impuestos-retenidos";

      return new Promise((resolve, reject) => {
        apiMunzen
          .get(url)
          .then((resp) => {
            const impuestosRetenidos = resp.data.data;
            commit("setAllImpuestosRetenidos", { impuestosRetenidos });
            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    toggleStatusImpuestoRetenido({ commit }, impuestoRetenidoId) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .get(`/impuestos-retenidos/toggle-status/${impuestoRetenidoId}`)
          .then(() => {
            commit("resetTaxesType");
            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
  },
};
