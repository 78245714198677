import apiMunzen from "../../api/ApiMunzen";
import router from "../../router";
import FileSaver from "file-saver";
import helper from "../../js/Helper";

let initialState = () => {
  return {
    clients: null,
    searchParams: {
      data_search: "",
      current_page: 1,
      pages: [],
      items_perpage: helper.pagination.itemsPerPage,
      existingSearchResults: true,
      defaultPagesInPaginationBar: helper.pagination.pagesNavigationBarQuantity, //this is the number of showed pages the user can move in pagination bar
      recordsPerPage: helper.pagination.paginationSizeOptions,
    },
    generalLoading: true,
    pagesLoading: false,
    searching: false,
  };
};
export default {
  namespaced: true,
  state: initialState,
  getters: {
    getClients(state) {
      return state.clients;
    },
    getSearchParams(state) {
      return state.searchParams;
    },
    anySearchParamsSet(state) {
      return (
        state.searchParams.current_page > 1 ||
        state.searchParams.data_search !== ""
      );
    },
    getExistingSearchResults(state) {
      return state.searchParams.existingSearchResults;
    },
  },
  mutations: {
    resetState(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
    setClients(state, payload) {
      // loading is added to use it when it needs to do an action with one register from the pagination
      // like when the status is changed
      payload.clients.data.forEach((client) => (client.loading = false));

      state.clients = payload.clients;
    },
    setExistingSearchResults(state, payload) {
      state.searchParams.existingSearchResults = payload.existingSearchResults;
    },
    resetUri: async function (state) {
      let page = router.currentRoute.query.page || 1;
      if (page > 1) {
        state.searchParams.current_page = 1;
        await router.replace({
          name: "clients",
          query: { page: "1" },
        });
      }
    },
    addClient(state, payload) {
      payload.client.loading = false;
      if (state.clients) {
        state.clients.pagination.total++;
      }

      //si se encuentra en la primera página y no tiene filtros aplicados entonces se añade el registro
      if (
        state.searchParams.current_page === 1 &&
        state.searchParams.data_search === "" &&
        state.clients
      ) {
        //si la longitud del arreglo de los clientes es = a los items mostrados por página
        //entonces se eliminá el último resultado para no mostrar elementos de más
        if (state.clients.data.length === state.searchParams.items_perpage) {
          state.clients.data.pop();
        }
        //Se añade el nuevo registro a la primera posición
        state.clients.data.unshift(payload.client);
      }
    },
    updateClient(state, payload) {
      //Esto validación es por si en el modal se edita un cliente sin haber cargado los clientes
      if (!state.clients) return;

      //Se actualiza la información del cliente editado y que se tiene guardado en memoria
      const indexClientToUpdate = state.clients.data.findIndex(
        (clientMemory) =>
          parseInt(clientMemory.id) === parseInt(payload.client.id),
      );

      state.clients.data.splice(indexClientToUpdate, 1, payload.client);
    },
  },
  actions: {
    getAllClients(context, reload = false) {
      if (context.state.clients && !reload) {
        context.state.searchParams.current_page =
          context.state.clients.pagination.current_page;
        return;
      }
      context.state.searchParams.current_page = parseInt(
        router.currentRoute.query.page || 1,
      );
      return new Promise((resolve, reject) => {
        apiMunzen
          .get(`/clients?page=${context.state.searchParams.current_page}`)
          .then((resp) => {
            const clients = resp.data;
            context.commit("setClients", { clients });
            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          })
          .finally(() => {
            context.state.generalLoading = false;
          });
      });
    },
    getAllClientsOrderByField(context, payload) {
      let field = "name_razon_social";
      let order = "asc";
      let page = 1;

      if (payload !== undefined) {
        field = payload.field || field;
        order = payload.order || order;
        page = payload.page || page;
      }

      return new Promise((resolve, reject) => {
        apiMunzen
          .get(
            `/clients?page=${page}&order_by=${field}&order_direction=${order}`,
          )
          .then((resp) => {
            const clients = resp.data;
            resolve(clients);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    getClientInfo({ state }, payload) {
      if (state.clients) {
        const clientFound = state.clients.data.find(
          (client) => parseInt(client.id) === parseInt(payload.clientId),
        );

        if (clientFound) {
          //Se retorna una copia del objeto encontrado para no alterar los datos originales
          return {
            ...state.clients.data.find(
              (client) => parseInt(client.id) === parseInt(payload.clientId),
            ),
          };
        }
      }

      return new Promise((resolve, reject) => {
        apiMunzen
          .get(`/clients/${payload.clientId}`)
          .then((resp) => {
            const client = resp.data.data;
            resolve(client);
          })
          .catch((error) => {
            reject({
              success: false,
              error: error.response,
            });
          });
      });
    },
    searchClients({ state, commit }, resetUri = true) {
      state.pagesLoading = true;
      state.searching = true;
      return new Promise((resolve, reject) => {
        apiMunzen
          .post(
            `/clients/search?page=${state.searchParams.current_page}`,
            state.searchParams,
          )
          .then((resp) => {
            const clients = resp.data;
            commit("setClients", { clients });
            commit("setExistingSearchResults", {
              existingSearchResults: clients.data.length > 0,
            });
            if (resetUri) {
              commit("resetUri");
            }
            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            reject(false);
          })
          .finally(() => {
            state.pagesLoading = false;
            state.searching = false;
          });
      });
    },
    searchClientsWithoutPagination(context, searchValue) {
      const searchData = {
        data_search: searchValue,
        paginated_search: false,
      };
      return new Promise((resolve, reject) => {
        apiMunzen
          .post(`/clients/search`, searchData)
          .then((resp) => {
            const clients = resp.data.data;
            resolve(clients);
          })
          .catch((error) => {
            console.log(error);
            reject(false);
          });
      });
    },
    saveClient({ commit }, client) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .post("/clients", client)
          .then((resp) => {
            const client = resp.data.data;
            commit("addClient", { client });
            resolve(client);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    updateClient({ commit }, client) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .put(`/clients/${client.id}`, client)
          .then((resp) => {
            const client = resp.data.data;

            commit("updateClient", { client });

            resolve(client);
          })
          .catch((error) => {
            console.log(error.response);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    deleteClient(context, payload) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .delete(`/clients/${payload.id}`)
          .then(() => {
            resolve({
              success: true,
            });
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    exportClients() {
      return new Promise((resolve, reject) => {
        apiMunzen
          .get(`/clients/export`, {
            responseType: "blob",
          })
          .then((response) => {
            FileSaver.saveAs(response.data, "Clientes");
            resolve({
              success: true,
            });
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    import({ commit }, formData) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .post(`/clients/import`, formData)
          .then((response) => {
            resolve(true);
          })
          .catch((error) => {
            console.log("error clients import", error.response);
            reject({
              success: false,
              statusCode: error.response.status,
              message:
                error.response.data.dataError ||
                "error al importar los clientes",
            });
          });
      });
    },
    importReport() {
      return new Promise((resolve, reject) => {
        apiMunzen
          .get(`/clients/import/report`)
          .then((respose) => {
            resolve(respose.data.data);
          })
          .catch((error) => {
            console.log("error clients import report", error.response);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    uniqueRfcClient({}, data) {
      return new Promise((resolve, reject) => {
        apiMunzen
          .post("/clients/rfc-unique", data)
          .then((response) => {
            resolve({
              unique: response.data.is_unique,
            });
          })
          .catch((error) => {
            reject({
              error: error.response,
            });
          });
      });
    },
    downloadClientsImportTemplate() {
      return new Promise((resolve, reject) => {
        apiMunzen
          .get(`/clients/import/download-import-clients-template`, {
            responseType: "blob",
          })
          .then((response) => {
            FileSaver.saveAs(
              response.data,
              "Plantilla para importar clientes.xlsx",
            );
            resolve({
              success: true,
            });
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          });
      });
    },
    checkIfIsTheLastElementInPagination({ state, dispatch }) {
      if (state.clients.data.length === 0) {
        if (state.searchParams.current_page > 1) {
          state.searchParams.current_page--;

          dispatch("searchClients", false);

          router
            .push({
              name: "clients",
              query: { page: state.searchParams.current_page.toString() },
            })
            .then();
        }
      }
    },
  },
};
