import accounting from "accounting";
import {round} from "reliable-round";
import moment from "moment/moment";
import {iziToastFunctions} from "./IziToastFunctions";

const helper = {
  pagination: {
    itemsPerPage: 30,
    pagesNavigationBarQuantity: 5,
    paginationSizeOptions: [
      {
        value: 30,
        text: "30",
      },
      {
        value: 50,
        text: "50",
      },
      {
        value: 100,
        text: "100",
      },
    ],
    pagesNavigationBar(currentPage, localTotalPages) {
      let pages = [];
      let initialPage;
      // se establece la página intermedia
      const leftRightPagesQuantity = Math.ceil(
        this.pagesNavigationBarQuantity / 2
      );

      //si la página actual es menor que leftRightPagesQuantity se muestran las primeras cinco páginas
      if (currentPage <= leftRightPagesQuantity) {
        initialPage = 1;
      }
        // si la página actual es mayor que leftRightPagesQuantity, pero menor que el total menos leftRightPagesQuantity
      // se muestran las páginas intermedias
      else if (
        currentPage > leftRightPagesQuantity &&
        currentPage <= localTotalPages - leftRightPagesQuantity
      ) {
        initialPage = currentPage - leftRightPagesQuantity + 1;
        pages.push("...");
      }
        // si la página actual es mayor que el total menos leftRightPagesQuantity
      // se muestran las últimas páginas
      else if (currentPage > localTotalPages - leftRightPagesQuantity) {
        initialPage = localTotalPages - this.pagesNavigationBarQuantity + 1;
        pages.push("...");
      }
      for (let i = initialPage; i <= localTotalPages; i++) {
        if (i === this.pagesNavigationBarQuantity + initialPage) {
          pages.push("...");
          break;
        }
        pages.push(i);
      }

      return pages;
    },
  },
  clavesSatCuentasPredial: [
    "80131500",
    "80131501",
    "80131502",
    "80131503",
    "80131504",
    "80131505",
    "80131506"
  ],
  rfcValidation(rfc, validateGenericForeignRfc = true) {

    if (validateGenericForeignRfc) {
      //RFC Genérico para clientes del extranjero
      if (rfc === "XEXX010101000") {
        return false;
      }
    }
    let expresion =
      /^[A-ZÑ\x26]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|[1][0-9]|2[0-9]|3[0-1])[A-Z0-9]{2}[0-9A]$/u;
    return expresion.test(rfc);
  },
  getPersonalidad(rfc) {
    if (!this.rfcValidation(rfc, false)) {
      return "el RFC ingresado no es válido";
    }
    return rfc.length === 12 ? "Moral" : "Física";
  },
  numberToCurrency(number, precision = 2) {
    if (number === "" || !number) return null;
    return accounting.formatMoney(number, "$", precision);
  },
  currencyToNumber(currency) {
    if (!currency) return null;

    return accounting.unformat(currency);
  },
  roundPrice(priceNumber, decimals = 2) {
    priceNumber = +priceNumber;
    decimals = +decimals;

    if (
      Number.isNaN(priceNumber) ||
      Number.isNaN(parseFloat(priceNumber)) ||
      !(typeof priceNumber === "number" && decimals % 1 === 0)
    ) {
      return NaN;
    }

    return round(priceNumber, decimals);
  },
  debounceAsyncValidator(validator, delay) {
    let currentTimer = null;
    let currentPromiseReject = null;

    function debounce() {
      return new Promise((resolve, reject) => {
        currentTimer = setTimeout(() => {
          currentTimer = null;
          currentPromiseReject = null;
          resolve();
        }, delay);
        currentPromiseReject = reject;
      });
    }

    return function (value) {
      if (currentTimer) {
        currentPromiseReject(new Error("replaced"));
        clearTimeout(currentTimer);
        currentTimer = null;
      }

      return validator.call(this, value, debounce);
    };
  },
  scrollToInputError(form) {
    setTimeout(() => {
      const errors = Object.entries(form.errors)
        .map(([key, value]) => ({
          key,
          value,
        }))
        .filter((error) => error["value"].length);
      form.refs[errors[0]["key"]].$el.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }, 100);
  },
  scrollToIndexTitle(component, refName = "scrollToIndexTitle") {
    let obj = component;
    while (obj) {
      if (obj.$refs[refName]) {
        obj.$refs[refName].scrollIntoView({
          block: "start",
        });
      }
      obj = obj.$parent;
    }
    return undefined;
  },
  scrollToTopPage() {
    window.scrollTo(0, 0);
  },
  highLightResults(searchValue, entries, keys) {
    entries.forEach((entry) => {
      keys.forEach((key) => {
        if (entry[key] !== null && entry[key] !== "undefined") {
          let normalizedValue = entry[key].toLowerCase();
          if (normalizedValue.includes(searchValue.toLowerCase())) {
            entry[key] = entry[key].replace(
              new RegExp(searchValue, "gi"),
              (match) => `<mark>${match}</mark>`
            );
          }
        }
      });
    });
  },

  quitarAcentos(string) {
    const acentos = {
      á: "a",
      é: "e",
      í: "i",
      ó: "o",
      ú: "u",
      Á: "A",
      É: "E",
      Í: "I",
      Ó: "O",
      Ú: "U",
    };
    return string
      .split("")
      .map((letra) => acentos[letra] || letra)
      .join("")
      .toString();
  },

  removeSpecialCasesRazonSocial(razonSocial) {
    //Se remueve lo que esté después del S. para las sociedades, que segun el SAT no debe incluir la razón social para poder facturar
    //ejemplos son S.C., S.A. de C.V., S.A.S., S. en N.C., etc
    const regex = /S\..*/;

    if (regex.test(razonSocial)) {
      iziToastFunctions.error(
        "El SAT no acepta regímenes societarios en la razón social"
      );
    }

    razonSocial = razonSocial.replace(regex, "");

    return razonSocial.trim();
  },
  humanReadableDate(date, showHour = false) {
    if (showHour) {
      return moment(date).format("D/MMMM/YYYY HH:mm:ss");
    } else {
      return moment(date).format("D/MMMM/YYYY");
    }
  },
  shortHumanReadableDate(date, showHour = false) {
    if (showHour) {
      return moment(date).format("D/MMM/YYYY HH:mm:ss");
    } else {
      return moment(date).format("D/MMM/YYYY");
    }
  },
  // Función para convertir una cadena Base64 en un Blob
  base64ToBlob(base64String) {
    return new Promise((resolve) => {
      //Se remueve el encabezado de la cadena base64
      const headerIndex = base64String.indexOf(";base64,");
      if (headerIndex !== -1) {
        base64String = base64String.slice(headerIndex + 8);
      }

      const byteCharacters = atob(base64String);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, {type: "image/png"});
      resolve(blob);
    });
  },

  validateSerie(serie) {
    const regex = new RegExp("^[a-zA-Z]+$");

    if (!regex.test(serie)) {
      iziToastFunctions.error(
        "La serie no puede contener números, caracteres especiales o espacios en blanco"
      );
      return false;
    }
    return true;
  },
  cleanDescription(description) {
    //Se limpia la descripción de etiquetas html
    description = description.replace(/<[^>]*>?/gm, "");

    //Se limpia la descripción de saltos de línea
    description = description.replace(/(\r\n|\n|\r)/gm, " ");

    return description;
  },

  cleanCuentaPredial(cuentaPredial) {
    //Se quita cualquier caracter especia, espacio o salto de linea
    return cuentaPredial.replace(/[^0-9a-zA-Z]/g, '');
  }
};

export default helper;
