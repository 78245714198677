import apiMunzen from "../../api/ApiMunzen";
import { iziToastFunctions } from "../../js/IziToastFunctions";
import moment from "moment/moment";

let initialState = () => {
  return {
    loadingInfo: true,
    savingData: false,
    originalCerFileName: "",
    originalKeyFileName: "",
    sellosFiscalesRegistered: false,
    sellosDigitalesFormData: {
      password: "",
      cer_file: null,
      key_file: null,
    },
    cerFile: {
      isExpired: false,
      initialDate: "",
      expirationDate: "",
      certificatedNumber: "",
    },
    backUpCerFile: {
      initialDate: "",
      expirationDate: "",
      certificatedNumber: "",
    },
  };
};
export default {
  namespaced: true,

  state: initialState,

  getters: {
    cerFileInitialDate(state) {
      return state.cerFile.initialDate;
    },

    showNewCerMessage(state) {
      //Si la fecha de inicio de los certificados es menor o igual a 72 hrs o 3d días se debe mostrar el mensaje de que los certificados son nuevos
      return (moment().diff(moment(state.cerFile.initialDate), 'days') <= 3)
    },

    cerFileExpirationDate(state) {
      return state.cerFile.expirationDate;
    },

    cerFileCertificatedNumber(state) {
      return state.cerFile.certificatedNumber;
    },

    cerFileIsExpired(state) {
      return state.cerFile.isExpired;
    },

    originalCerFileName(state) {
      const totalVisibleChars = 50;
      const maxChars = 20;
      if (state.originalCerFileName.length > totalVisibleChars) {
        //We get the last maxChars in the variable
        let newString =
          state.originalCerFileName.slice(
            0,
            maxChars - state.originalCerFileName.length
          ) + "...";

        //show the las 6 chars including the extension
        newString += state.originalCerFileName.substring(
          state.originalCerFileName.length - 6
        );
        return newString;
      }

      return state.originalCerFileName;
    },

    originalKeyFileName(state) {
      const totalVisibleChars = 30;
      const maxChars = 20;
      if (state.originalKeyFileName.length > totalVisibleChars) {
        //We get the last maxChars in the variable
        let newString =
          state.originalKeyFileName.slice(
            0,
            maxChars - state.originalKeyFileName.length
          ) + "...";

        //the it's added the las 9 chars including the extension
        newString += state.originalKeyFileName.substring(
          state.originalKeyFileName.length - 9
        );
        return newString;
      }

      return state.originalKeyFileName;
    },

    sellosFiscalesRegistered(state) {
      return state.sellosFiscalesRegistered;
    },

    loadingInfo(state) {
      return state.loadingInfo;
    },

    savingData(state) {
      return state.savingData;
    },
  },
  mutations: {
    setSellosDigitalesInfo(state, payload) {
      state.cerFile.isExpired = payload.is_expired;
      state.cerFile.initialDate = payload.initial_date;
      state.cerFile.expirationDate = payload.expiration_date;
      state.cerFile.certificatedNumber = payload.certificated_number;
      state.originalCerFileName = payload.original_cer_file_name;
      state.originalKeyFileName = payload.original_key_file_name;
      state.sellosFiscalesRegistered = payload.sellos_fiscales_registered;
    },

    setCerFileInfo(state, payload) {
      state.cerFile.isExpired = payload.is_expired;
      state.cerFile.initialDate = payload.initial_date;
      state.cerFile.expirationDate = payload.expiration_date;
      state.cerFile.certificatedNumber = payload.certificated_number;
    },

    disableLoadingInfo(state) {
      state.loadingInfo = false;
    },

    enableSavingData(state) {
      state.savingData = true;
    },

    disableSavingData(state) {
      state.savingData = false;
    },

    disableSellosFiscalesRegistered(state) {
      state.sellosFiscalesRegistered = true;
    },

    editSellosFiscales(state) {
      state.sellosFiscalesRegistered = false;
      state.cerFile.isExpired = false;

      //cer file info is save in another variables because
      //its possible the user cancel the edit action
      state.backUpCerFile.initialDate = state.cerFile.initialDate;
      state.backUpCerFile.expirationDate = state.cerFile.expirationDate;
      state.backUpCerFile.certificatedNumber = state.cerFile.certificatedNumber;

      //cer file info is deleted to store the new cer file values
      state.cerFile.initialDate = "";
      state.cerFile.expirationDate = "";
      state.cerFile.certificatedNumber = "";
    },

    cancelEditSellosFiscales(state) {
      state.sellosFiscalesRegistered = true;
      state.cerFile.isExpired = false;

      //cer file old values are recovered because edit was canceled
      state.cerFile.initialDate = state.backUpCerFile.initialDate;
      state.cerFile.expirationDate = state.backUpCerFile.expirationDate;
      state.cerFile.certificatedNumber = state.backUpCerFile.certificatedNumber;
    },

    updateCerFileFormData(state, payload) {
      state.sellosDigitalesFormData.cer_file = payload;
    },

    updateKeyFileFormData(state, payload) {
      state.sellosDigitalesFormData.key_file = payload;
    },

    updatePasswordFormData(state, payload) {
      state.sellosDigitalesFormData.password = payload;
    },

    resetFormDataValues(state) {
      state.sellosDigitalesFormData.cer_file = null;
      state.sellosDigitalesFormData.key_file = null;
      state.sellosDigitalesFormData.password = "";
    },

    resetState(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    getCerFileInfo({ commit }, cerFile) {
      if (!cerFile) return;

      const formData = new FormData();
      formData.append("cer_file", cerFile);

      return new Promise((resolve, reject) => {
        apiMunzen
          .post(`/sellos-fiscales/get-cer-file-info`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            commit("setCerFileInfo", response.data.data);
            resolve(true);
          })
          .catch((error) => {
            iziToastFunctions.error(
              "Ocurrio un error, verifique que los datos son correctos"
            );
            console.log(error);
            reject(false);
          });
      });
    },
    updateSellosDigitalesInitialConfiguration({ state, rootState }, data) {
      if (state.cerFile.isExpired) {
        iziToastFunctions.error("Sus sellos ya han expirado.");
        return;
      }

      return new Promise((resolve) => {
        apiMunzen
          .post(
            `/new-client/sellos-fiscales/${rootState.InitialConfig.newClientToken}`,
            data,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(() => resolve(true))
          .catch(() => resolve(false));
      });
    },
    getSellosDigitalesInitialConfiguration({ commit, rootState }) {
      return new Promise(() => {
        apiMunzen
          .get(
            `/new-client/sellos-fiscales/${rootState.InitialConfig.newClientToken}`
          )
          .then((response) => {
            commit("setSellosDigitalesInfo", response.data.data);
            commit("disableLoadingInfo");
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    getSellosDigitales({ commit, rootState }) {
      return new Promise(() => {
        apiMunzen
          .get(`/sellos-fiscales/${rootState.Auth.enterprise.id}`)
          .then((response) => {
            commit("setSellosDigitalesInfo", response.data.data);
            commit("disableLoadingInfo");
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },
    updateSellosDigitales({ state, commit, rootState }, formData) {
      if (state.cerFile.isExpired) {
        iziToastFunctions.error("Sus sellos ya han expirado.");
        return;
      }

      return new Promise((resolve) => {
        apiMunzen
          .post(`sellos-fiscales/${rootState.Auth.enterprise.id}`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            // Get original .cer and .key file name
            for (const value of formData.entries()) {
              if (value[0] === "cer_file") {
                state.originalCerFileName = value[1].name;
              } else if (value[0] === "key_file") {
                state.originalKeyFileName = value[1].name;
              }
            }

            resolve(true);
          })
          .catch((error) => resolve(false));
      });
    },
  },
};
