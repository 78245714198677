import apiMunzen from "../../api/ApiMunzen";
import helper from "../../js/Helper";
import router from "../../router";

let initialState = () => {
  return {
    spendings: null,
    searchParams: {
      data_search: "",
      current_page: 1,
      pages: [],
      items_perpage: helper.pagination.itemsPerPage,
      existingSearchResults: true,
    },
    generalLoading: true,
    pagesLoading: false,
    searching: false,
    currentOrder: null, // used to store the current order being viewed
  };
};

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getSpendings(state) {
      return state.spendings;
    },
    getExistingSearchResults(state) {
      return state.searchParams.existingSearchResults;
    },
  },
  mutations: {
    setSpendings(state, payload) {
      state.spendings = payload.spendings;
    },
    resetState(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach((key) => {
        state[key] = s[key];
      });
    },
  },
  actions: {
    fetchSpendingsCreditsUser(context, page = null) {
      context.state.searching = true;
      context.state.pagesLoading = true;

      if (page) {
        context.state.searchParams.current_page = page;
      } else {
        context.state.searchParams.current_page = parseInt(
          router.currentRoute.query.page || 1
        );
      }

      return new Promise((resolve, reject) => {
        apiMunzen
          .get(
            `/user-credits-spendings?page=${context.state.searchParams.current_page}`
          )
          .then((resp) => {
            const spendings = resp.data;
            context.commit("setSpendings", { spendings });
            resolve(true);
          })
          .catch((error) => {
            console.log(error);
            reject({
              success: false,
              statusCode: error.response.status,
            });
          })
          .finally(() => {
            context.state.generalLoading = false;
            context.state.searching = false;
            context.state.pagesLoading = false;
          });
      });
    },
  },
};
