import apiMunzen from "../../api/ApiMunzen";

let initialState = () => {
  return {
    seriesFacturasIngreso: [],
    seriesFacturasGlobales: [],
  }
}

export default {
  namespaced: true,
  state: initialState,
  getters: {
    getSeriesFacturasIngreso(state) {
      return state.seriesFacturasIngreso.map(function (serieFacturaIngreso) {
        return {
          id: serieFacturaIngreso.id,
          serie: serieFacturaIngreso.serie,
          folio: serieFacturaIngreso.folio
        }
      })
    },
    getSeriesFacturasGlobales(state) {
      return state.seriesFacturasGlobales.map(function (serieFacturaGlobal) {
        return {
          id: serieFacturaGlobal.id,
          serie: serieFacturaGlobal.serie,
          folio: serieFacturaGlobal.folio
        }
      })
    },
  },
  mutations: {
    resetListOfFacturasIngreso(state) {

      state.seriesFacturasIngreso = []

    },
    setSeriesFacturasIngreso(state, payload) {
      state.seriesFacturasIngreso = payload
    },
    setSeriesFacturasGlobales(state, payload) {
      state.seriesFacturasGlobales = payload
    },
    resetState(state) {
      // acquire initial state
      const s = initialState()
      Object.keys(s).forEach(key => {
        state[key] = s[key]
      })
    },
  },
  actions: {
    save({commit, rootState}, newSerieData) {

      return new Promise((resolve, reject) => {
        apiMunzen.post(`/series/${rootState.Auth.enterprise.id}`, newSerieData)
          .then((response) => {

            const serieCreated = response.data.data
            commit('resetListOfFacturasIngreso')

            resolve(serieCreated.id)
          })
          .catch(error => {
            console.log(error.response)
            reject({
              success: false,
              error: error.response
            })
          })
          .finally(() => this.processing = false)
      })

    },

    update({commit}, serieToUpdateData) {

      return new Promise((resolve, reject) => {
        apiMunzen.put(`/series/${serieToUpdateData.id}`, serieToUpdateData)
          .then(() => {
            commit("resetListOfFacturasIngreso")
            resolve(true)
          })
          .catch(error => {
            console.log(error)
            reject({
              success: false,
              error: error.response
            })
          })
      })

    },
    delete({commit}, serieToRemoveId) {
      return new Promise((resolve, reject) => {
        apiMunzen.delete(`/series/${serieToRemoveId}`)
          .then(() => {
            commit("resetListOfFacturasIngreso")
            resolve(true)
          })
          .catch(error => {
            console.log(error)
            reject({
              success: false,
              statusCode: error.response.status
            })
          })

      })
    },
    fetchFacturasIngresoSeries({state, commit, rootState}) {
      if (state.seriesFacturasIngreso.length === 0) {
        return new Promise((resolve) => {
          apiMunzen.get(`/series/${rootState.Auth.enterprise.id}/facturas-ingreso`)
            .then(response => {
              const seriesFacturasIngresoResponse = response.data.data
              commit('setSeriesFacturasIngreso', seriesFacturasIngresoResponse)
              resolve(seriesFacturasIngresoResponse)
            }).catch(error => {
            console.log(error.response)
            resolve(false)
          })
        })
      } else {
        return state.seriesFacturasIngreso
      }
    },
    fetchFacturasGlobalesSeries({state, commit, rootState}) {
      if (state.seriesFacturasGlobales.length === 0) {
        return new Promise((resolve) => {
          apiMunzen.get(`/series/${rootState.Auth.enterprise.id}/facturas-globales`)
            .then(response => {
              const seriesFacturasGlobalesResponse = response.data.data
              commit('setSeriesFacturasGlobales', seriesFacturasGlobalesResponse)
              resolve(seriesFacturasGlobalesResponse)
            }).catch(error => {
            console.log(error.response)
            resolve(false)
          })
        })
      } else {
        return state.seriesFacturasGlobales
      }
    }
  }
}
